import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import Panel from '../components/Panel';
import Login from '../components/Login';
import { useAuth } from '../hooks/AuthContext';

interface RouteProps {
  isPrivate?: boolean;
  component: ReactNode;
}

const ProxyRoute: React.FC<RouteProps> = ({ component, isPrivate = false }) => {
  const { user } = useAuth();

  const Layout = user ? Panel : Login;

  return isPrivate === !!user ? (
    <Layout>
      {component}
    </Layout>
  ) : (
    <Navigate
      to={isPrivate ? '/' : '/dashboard'}
    />
  )

};

export default ProxyRoute;
