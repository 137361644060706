import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../utils/handleSelects';
import { moneyMask } from '../../../../utils/handleMasks';

import Confirmation from '../../../../components/Confirmation';
import Select from '../../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
  FormFieldInput,
} from '../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  cliente?: string;
  parceiro?: string;
  percentual: string | null;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientPartnerRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idClient } = useParams<{ idClient: string }>();
  const { idClientPartner } = useParams<{ idClientPartner: string }>();
  const { addToast } = useToasts();
  const [idClientPartnerState, setIdClientPartnerState] = useState(
    idClientPartner,
  );

  const [entity, setEntity] = useState<EntityData>({
    cliente: idClient,
    parceiro: '',
    percentual: '0',
  });

  const [selectSuppliers, setSelectSuppliers] = useState([]);

  const [entityValidation] = useState({
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    parceiro: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Parceiro'),
    percentual: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Valor %')
      .min(0.01, 'Informe um valor maior que 0'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/suppliers');
        setSelectSuppliers(
          changeNamesOfColumns(response.data, 'razaoSocial', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idClientPartner !== undefined) {
        const response = await api.get(`/clientPartners/${idClientPartner}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['percentual'],
          [],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idClientPartner]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue = '';
    if (type === 'money') {
      newValue = moneyMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['percentual']);
        if (idClientPartner === undefined) {
          const response = await api.post('/clientPartners', entitySave);
          setIdClientPartnerState(response.data.id);
          navigate(
            `/clients/register/${idClient}/partner/${response.data.id}`,
            { replace: true }
          );
          addToast('Comissão de parceiro inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(`/clientPartners/${idClientPartner}`, entitySave);
          addToast('Comissão de parceiro editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar a comissão do parceiro, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSupplier(supplier: string) {
    const entityModified: EntityData = entity;
    entityModified.parceiro = supplier;

    setEntity(entityModified);
    document.getElementById('percentual')?.focus();
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Comissões de Parceiros</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="80%">
              <span>
                {`Parceiro *
                ${errors.parceiro ? `(${errors.parceiro})` : ''}
                `}
              </span>
              <Select
                name="parceiro"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o parceiro"
                onBlur={(e: any) => {
                  handleValidation('parceiro');
                }}
                onChange={(e: any) => {
                  handleSupplier(e.value);
                }}
                error={!!errors.parceiro}
                defaultValue={entity.parceiro}
                options={selectSuppliers}
              />
            </FormField>
            <FormField size="20%">
              <span>
                {`Valor %
                ${errors.percentual ? `(${errors.percentual})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.percentual ? entity.percentual : ''}
                autoComplete="off"
                id="percentual"
                error={!!errors.percentual}
                placeholder="Digite o valor do percentual"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientPartnerRegister;
