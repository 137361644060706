import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, endOfMonth, startOfMonth } from 'date-fns';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaRandom,
} from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';

import api from '../../services/api';

import {
  manipulateDates,
  manipulateDateHour,
  orderBy as handleOrderBy,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  TableTd,
  TablesPagination,
  ButtonLink,
} from '../../styles/lists';
import { changeNamesOfColumns } from '../../utils/handleSelects';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  data?: string;
  cliente?: string;
  colaborador?: string;
  status?: string;
  tipo?: string;
  descricao?: string;
}

const CustomerService: React.FC = () => {
  const navigate = useNavigate();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'data',
    desc: true,
  });

  const filters: any = getFilters('customerServices');

  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [colaborador, setColaborador] = useState(
    Object.keys(filters).length > 0 ? filters.colaborador : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : startOfMonth(new Date()),
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : endOfMonth(new Date()),
  );
  const [status, setStatus] = useState(
    Object.keys(filters).length > 0 ? filters.status : 'ANDAMENTO',
  );
  const [tipo, setTipo] = useState(
    Object.keys(filters).length > 0 ? filters.tipo : '',
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );

  function saveFilters() {
    setFilters(
      {
        cliente,
        colaborador,
        dataInicial,
        dataFinal,
        status,
        tipo,
        descricao,
      },
      'customerServices',
    );
  }

  const [selectColaboradores, setSelectColaboradores] = useState([]);

  const [selectStatus] = useState([
    { label: 'ANDAMENTO', value: 'ANDAMENTO' },
    { label: 'FINALIZADO', value: 'FINALIZADO' },
  ]);

  const [selectTipo] = useState([
    { label: 'MANUTENÇÃO', value: 'MANUTENÇÃO' },
    { label: 'DÚVIDA', value: 'DÚVIDA' },
    { label: 'BUG', value: 'BUG' },
    { label: 'TREINAMENTO', value: 'TREINAMENTO' },
  ]);

  useEffect(() => {
    async function loadCollaborators() {
      const response = await api.get('/collaborators', {
        params: {
          status: 'ativo'
        }
      });
      setSelectColaboradores(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCollaborators();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/customerServices', {
          params: {
            cliente,
            colaborador,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
            status,
            tipo,
            descricao,
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            data: item.data,
            cliente: item.clienteDados.fantasia,
            colaborador: item.colaboradorDados.nome,
            status: item.status,
            tipo: item.tipo,
            descricao:
              // eslint-disable-next-line no-nested-ternary
              item.descricao !== null
                ? item.descricao.length>100 ? `${String(item.descricao).substring(0,100)} ...` : item.descricao
                : '',
            };
        });

        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [cliente, descricao, colaborador, dataInicial, dataFinal, status, tipo, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/customerServices/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/customerServices/register');
  }

  function handlePanel() {
    navigate('/customerServices/panel');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Atendimentos</h1>
      </Title>
      <Buttons spaceBetween>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <ButtonLink onClick={handlePanel}>
          <FaRandom size={18} color="#faede8" />
          Trocar para painel de feedback
        </ButtonLink>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="20%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="17%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
          <FiltersField size="17%">
            <Select
              name="colaborador"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo colab."
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setColaborador(e.value);
                } else {
                  setColaborador('');
                }
              }}
              defaultValue={colaborador}
              options={selectColaboradores}
            />
          </FiltersField>
          <FiltersField size="16%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição"
            />
          </FiltersField>
          <FiltersField size="15%">
            <Select
              name="status"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo status"
              onChange={(e: any) => {
                if (e !== null) {
                  setStatus(e.value);
                } else {
                  setStatus('');
                }
              }}
              defaultValue={status}
              options={selectStatus}
            />
          </FiltersField>
          <FiltersField size="15%">
            <Select
              name="tipo"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo tipo"
              onChange={(e: any) => {
                if (e !== null) {
                  setTipo(e.value);
                } else {
                  setTipo('');
                }
              }}
              defaultValue={tipo}
              options={selectTipo}
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('data');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('data', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('data', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Cliente
                  <button
                    onClick={() => {
                      orderBy('cliente');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cliente', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cliente', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Colaborador
                  <button
                    onClick={() => {
                      orderBy('colaborador');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('colaborador', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('colaborador', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Descrição
                  <button
                    onClick={() => {
                      orderBy('descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Status
                  <button
                    onClick={() => {
                      orderBy('status');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('status', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('status', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Tipo
                  <button
                    onClick={() => {
                      orderBy('tipo');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('tipo', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('tipo', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="10%">
                      {e.data && manipulateDateHour(e.data)}
                    </TableTd>
                    <TableTd width="20%">{e.cliente}</TableTd>
                    <TableTd width="20%">{e.colaborador}</TableTd>
                    <TableTd width="20%">{e.descricao}</TableTd>
                    <TableTd width="10%">{e.status}</TableTd>
                    <TableTd width="10%">{e.tipo}</TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${
              'id' in entity[0] ? entity.length : 0
            } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default CustomerService;
