import React from 'react';

import { AuthProvider } from './AuthContext';
import { ReactComponentProps } from '../utils/general';

const AppProvider: React.FC<ReactComponentProps> = ({ children }) => (
  <AuthProvider>{children}</AuthProvider>
);

export default AppProvider;
