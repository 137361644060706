import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';

import { useAuth } from '../../../../../hooks/AuthContext';

import {
  getIndividualErrors,
  getErrors,
} from '../../../../../utils/validateForm';
import {
  changeForm,
  saveForm,
  loadForm,
} from '../../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../../utils/handleSelects';

import Select from '../../../../../components/Select';
import Confirmation from '../../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  tarefaTopico: string | undefined;
  colaborador: string;
}

interface ErrorsData {
  [key: string]: string;
}

const TaskTopicTestRegister: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const { idTopic } = useParams<{ idTopic: string }>();
  const { idTest } = useParams<{
    idTest: string;
  }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    tarefaTopico: idTopic,
    colaborador: '',
  });

  const [selectCollaborators, setSelectCollaborators] = useState([]);

  const [entityValidation] = useState({
    tarefaTopico: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Tarefa Tópico'),
    colaborador: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Colaborador'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadCollaborator() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        handleChanges('colaborador', response.data[0].id);
      } catch (err) {
        //
      }
    }
    setTimeout(() => {
      loadCollaborator();
    }, 1000);
  }, [user.id]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            status: 'ativo'
          }
        });
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idTest !== undefined) {
        const response = await api.get(`/taskTopicTests/${idTest}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idTest]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idTest === undefined) {
          await api.post('/taskTopicTests', entitySave);
          addToast('Teste inserido com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(`/taskTopicTests/${idTest}`, entitySave);
          addToast('Teste editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o teste, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de teste de colaborador</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => handleValidation('colaborador')}
                onChange={(e: any) => {
                  handleChanges('colaborador', e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default TaskTopicTestRegister;
