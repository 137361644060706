import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaRandom,
} from 'react-icons/fa';

import DatePicker from '../../../components/DatePicker';

import api from '../../../services/api';

import {
  manipulateDates,
  orderBy as handleOrderBy,
} from '../../../utils/handleLists';
import { setFilters, getFilters } from '../../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldBetween,
  Tables,
  TableTd,
  TablesPagination,
  ButtonLink,
} from '../../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  fantasia?: string;
  telefone1?: string;
  telefone2?: string;
  telefone3?: string;
  email1?: string;
  email2?: string;
  ultimoAtendimento?: string;
}

const CustomerServicePanel: React.FC = () => {
  const navigate = useNavigate();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'fantasia',
    desc: false,
  });

  const filters: any = getFilters('customerServicesPanel');

  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );

  function saveFilters() {
    setFilters(
      {
        cliente,
        dataInicial,
        dataFinal,
      },
      'customerServicesPanel',
    );
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get('/customerServices/feedback', {
          params: {
            cliente,
            dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            fantasia: item.fantasia,
            telefone1: item.telefone1,
            telefone2: item.telefone2,
            telefone3: item.telefone3,
            email1: item.email1,
            email2: item.email2,
            ultimoAtendimento: item.ultimoatendimento,
          };
        });
        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [cliente, dataInicial, dataFinal, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleCustomerServices() {
    navigate('/customerServices');
  }

  return (
    <Container>
      <Title>
        <h1>Painel de Feedback de atendimentos</h1>
      </Title>
      <Buttons spaceBetween>
        <div><></></div>
        <ButtonLink onClick={handleCustomerServices}>
          <FaRandom size={18} color="#faede8" />
          Trocar para atendimentos
        </ButtonLink>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="30%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FiltersField size="70%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Cliente
                  <button
                    onClick={() => {
                      orderBy('fantasia');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('fantasia', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('fantasia', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Telefones
                  <button
                    onClick={() => {
                      orderBy('telefone1');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('telefone1', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('telefone1', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  E-mails
                  <button
                    onClick={() => {
                      orderBy('email1');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('email1', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('email1', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Ult. Atend.
                  <button
                    onClick={() => {
                      orderBy('ultimoAtendimento');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('ultimoAtendimento', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('ultimoAtendimento', true)}
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {'fantasia' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="40%">{e.fantasia}</TableTd>
                    <TableTd width="15%">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ margin: '3px 0' }}>{e.telefone1}</span>
                        <span style={{ margin: '3px 0' }}>{e.telefone2}</span>
                        <span style={{ margin: '3px 0' }}>{e.telefone3}</span>
                      </div>
                    </TableTd>
                    <TableTd width="35%">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ margin: '3px 0' }}>{e.email1}</span>
                        <span style={{ margin: '3px 0' }}>{e.email2}</span>
                      </div>
                    </TableTd>
                    <TableTd width="10%">
                      {e.ultimoAtendimento ? manipulateDates(e.ultimoAtendimento) : 'Sem registro'}
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default CustomerServicePanel;
