import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSend, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import ClipLoader from 'react-spinners/ClipLoader';
import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormFieldTextArea,
} from '../../../styles/registers';
import { changeNamesOfColumns } from '../../../utils/handleSelects';

import { FormLoading } from '../../../styles/lists';

import { getFilters } from '../../../utils/handleFilters';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  emailSubject: string;
  assunto: string;
  corpo: string;
}

interface ErrorsData {
  [key: string]: string;
}

const SendEmailSubject: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  const [selectEmailSubjects, setSelectEmailSubjects] = useState([]);

  const [entity, setEntity] = useState<EntityData>({
    emailSubject: '',
    assunto: '',
    corpo: '',
  });

  const [entityValidation] = useState({
    descricao: Yup.string().label('Assunto de E-mail'),
    assunto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Assunto'),
    corpo: Yup.string().required('Preenchimento obrigatório').label('Corpo'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/emailSubjects');
        setSelectEmailSubjects(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();
  }, []);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        setLoading(true);
        const { ids } = getFilters('receipts-selected-records');
        await api.post('/receipts/paymentSlip/emailSubject/sendEmail', {
          ids,
          assunto: entity.assunto,
          corpo: entity.corpo,
        });
        setLoading(false);
        addToast('Processo concluído', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao enviar e-mail, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleInformationEmailSubject(value: any) {
    if (value) {
      try {
        const response = await api.get(`/emailSubjects/${value}`);

        entity.assunto = response.data.assunto;
        entity.corpo = response.data.corpo;
        entity.emailSubject = value;
        setEntity(entity);
      } catch (err) {
        //
      }
    }
  }

  return (
    <Container>
      <Title>
        <h1>Envio de E-mails financeiros</h1>
      </Title>
      {loading ? (
        <FormLoading>
          <ClipLoader size={30} color="#FFFFFF" loading={loading} />
        </FormLoading>
      ) : (
        <Form>
          <FormBlock>
            <FormTitle>INFORMAÇÕES</FormTitle>
            <FormLine>
              <FormField size="50%">
                <span>
                  {`Assunto do E-mail *
                ${errors.emailSubject ? `(${errors.emailSubject})` : ''}
                `}
                </span>
                <Select
                  name="emailSubject"
                  height={40}
                  maxMenuHeight={300}
                  placeholder="Escolha o assunto do e-mail"
                  onBlur={(e: any) => handleValidation('emailSubject')}
                  onChange={(e: any) => {
                    handleChanges('emailSubject', e.value);
                    handleInformationEmailSubject(e.value);
                  }}
                  error={!!errors.emailSubject}
                  defaultValue={entity.emailSubject}
                  options={selectEmailSubjects}
                />
              </FormField>
              <FormField size="50%">
                <span>
                  {`
                Assunto *
                ${errors.assunto ? `(${errors.assunto})` : ''}
                `}
                </span>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => handleChanges(e.target.id, e.target.value)}
                  value={entity.assunto}
                  id="assunto"
                  autoComplete="off"
                  error={!!errors.assunto}
                  placeholder="Digite o assunto"
                />
              </FormField>
            </FormLine>
            <FormLine height="250px">
              <FormField size="100%">
                <span>
                  {`Corpo
                ${errors.corpo ? `(${errors.corpo})` : ''}
                `}
                </span>
                <FormFieldTextArea
                  height="250px"
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value);
                  }}
                  value={entity.corpo}
                  autoComplete="off"
                  id="corpo"
                  error={!!errors.corpo}
                  placeholder="Digite as informações do corpo"
                />
              </FormField>
            </FormLine>
          </FormBlock>
          <FormButtons>
            <Button onClick={handleSubmit} type="button">
              <FiSend size={20} color="#ffffff" />
              Enviar
            </Button>
            <Button
              visual="secondary"
              type="button"
              onClick={() => {
                Confirmation(
                  () => navigate(-1),
                  'Tem certeza que deseja cancelar o envio ?',
                  'abort',
                );
              }}
            >
              <FiXCircle size={20} color="#1362f5" />
              Cancelar
            </Button>
          </FormButtons>
        </Form>
      )}
    </Container>
  );
};

export default SendEmailSubject;
