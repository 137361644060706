import styled from 'styled-components';

interface CustomFiltersLineProps {
  first?: boolean;
  second?: boolean;
}

interface FiltersFieldProps {
  size: string;
}

export const FilterDiv = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FilterContentDiv = styled.div`
  width: 100%;
`;

export const FilterButtonDiv = styled.div`
  width: 40px;
  margin-right: 20px;
  margin-left: -6px;
`;

export const CustomFiltersLine = styled.div<CustomFiltersLineProps>`
  width: 100%;
  height: 50px;
  padding: 0 10px;
  margin-top: ${props => (props.first ? '17px' : '7px')};
  margin-bottom: ${props => props.second && '17px'};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FiltersFieldSwitch = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  border-radius: 8px;
  h3 {
    color: #a39f9d;
    width: 40%;
    font-size: 13px;
  }
`;

export const FiltersFieldBetween = styled.div<FiltersFieldProps>`
  width: ${props => props.size};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 8px;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #2e2c2c;
  border-radius: 8px;
  > div {
    width: 100%;
  }
  h3 {
    color: #a39f9d;
    font-size: 13px;
    padding: 0 5px;
  }
`;

