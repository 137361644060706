import styled from 'styled-components';
import { shade } from 'polished';

interface ButtonPaymentsProps {
  color?: string;
}

export const ButtonPaymentLine = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const ButtonPayments = styled.button<ButtonPaymentsProps>`
  border: 0;
  background: ${props => (props.color ? props.color : '#888888')};
  width: 80px;
  height: 27px;
  margin: 0 3px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 12px;
    color: #fafafa;
    margin-left: 5px;
  }
  transition: background-color 0.2s;
  &:hover {
    background: ${props =>
      props.color ? shade(0.2, props.color) : shade(0.2, '#888888')};
  }
  &:disabled {
    opacity: 0.1;
    cursor: not-allowed;
    &:hover {
      opacity: 0.1;
      background: ${props => (props.color ? props.color : '#888888')};
    }
  }
`;

export const FilterDiv = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FilterContentDiv = styled.div`
  width: 100%;
`;

export const FilterButtonDiv = styled.div`
  width: 40px;
  margin-right: 20px;
  margin-left: -6px;
`;
