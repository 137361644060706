import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaSyncAlt,
} from 'react-icons/fa';
import { FiCheck, FiSearch, FiUser, FiUserCheck, FiUserMinus, FiX } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import ClipLoader from 'react-spinners/ClipLoader';

import Switch from 'react-switch';

import { format, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import Select from '../../components/Select';

import { changeNamesOfColumns } from '../../utils/handleSelects';
import { manipulateDates, orderBy as handleOrderBy } from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  FiltersFieldSwitch,
  Tables,
  TableTd,
  TablesPagination,
  FormLoading,
  TableTdCard,
} from '../../styles/lists';
import apiEasyCloudGerencial from '../../services/apiEasyCloudGerencial';
import api from '../../services/api';
import apiEasyCloud from '../../services/apiEasyCloud';
import DatePicker from '../../components/DatePicker';
import confirmation from '../../components/Confirmation';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  codigo?: string;
  cpfCnpj?: string;
  razaoSocial?: string;
  fantasia?: string;
  cidade?: string;
  dataInativo?: Date;
  dataInclusao?: string;
  situacao?: string;
}

const ManagementsCompanies: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'razaoSocial',
    desc: false,
  });

  const filters: any = getFilters('managements');

  const [codigo, setCodigo] = useState(
    Object.keys(filters).length > 0 ? filters.codigo : '',
  );
  const [nome, setNome] = useState(
    Object.keys(filters).length > 0 ? filters.nome : '',
  );
  const [inativos, setInativos] = useState(
    Object.keys(filters).length > 0 ? filters.inativos : false,
  );
  const [cidades, setCidades] = useState<any>(
    Object.keys(filters).length > 0 ? filters.cidades : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null && filters.dataInicial !== '' && filters.dataInicial !== undefined
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null && filters.dataFinal !== '' && filters.dataFinal !== undefined
      ? parseISO(filters.dataFinal)
      : null,
  );

  const [selectCidades, setSelectCidades] = useState([]);

  function saveFilters() {
    setFilters(
      {
        codigo,
        nome,
        inativos,
        cidades,
        dataInicial,
        dataFinal,
      },
      'managements',
    );
  }

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const status = inativos ? {} : { status: 'ativo' };
        const response = await apiEasyCloudGerencial.get('/companies', {
          auth: {
            username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
            password: String(
              process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
            ),
          },
          params: {
            codigo,
            nome,
            cidadeNome: cidades,
            ...status,
            dataInclusaoInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            dataInclusaoFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            cpfCnpj: item.cpfCnpj,
            razaoSocial: item.razaoSocial,
            fantasia: item.fantasia,
            cidade: item.cidades?.nome,
            dataInativo: item.dataInativo,
            dataInclusao: item.created_at,
            situacao: item.situacao,
          };
        });

        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(await responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
        setLoading(false);
      } catch (err) {
        setEntity([{}]);
        setLoading(false);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters();
  }, [codigo, nome, inativos, cidades, order, dataInicial, dataFinal]);

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cities');
      setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCities();
  }, []);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  async function handleRunMigrations() {
    try {
      setLoading(true);
      await apiEasyCloud.post(
        '/migrations',
        {},
        {
          auth: {
            username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
            password: String(
              process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
            ),
          },
        },
      );
      setLoading(false);
      addToast('Migrations rodadas com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      addToast('Problemas ao rodar as migrations, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleGenerateBackups() {
    try {
      setLoading(true);
      await apiEasyCloud.post(
        '/dumps',
        {},
        {
          auth: {
            username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
            password: String(
              process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
            ),
          },
        },
      );
      setLoading(false);
      addToast('Backups dos bancos de dados gerados com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      addToast('Problemas ao gerar os backups dos bancos de dados, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/managements/register/${id}`);
  }

  interface IRegisterProductModule {
    clientProductId: string;
    moduleName: string;
    productId: string;
  }

  async function clientProductModuleRegistered(clientProductId: string, productModuleId: string): Promise<boolean> {
    try {
      const responseFindClientProductModule = await api.get(`/clientProductModules`, {
        params: {
          clienteProduto: clientProductId,
          produtoModulo: productModuleId,
        }
      });

      return responseFindClientProductModule.data.length > 0;
    } catch (error) {
      return false;
    }
  }

  async function registerProductModule(data: IRegisterProductModule): Promise<void> {
    try {
      const responseFindProductModule = await api.get(`/productModules`, {
        params: {
          produto: data.productId,
          descricao: data.moduleName,
        }
      });

      if (responseFindProductModule.data.length > 0) {
        if (!await clientProductModuleRegistered(data.clientProductId, responseFindProductModule.data[0].id)) {
          try {
            await api.post(`/clientProductModules`, {
              clienteProduto: data.clientProductId,
              produtoModulo: responseFindProductModule.data[0].id,
            });
          } catch (err: any) {
            addToast(
              `Houve um problema ao cadastrar o módulo ${data.moduleName} do produto EasyCloud. ${err.response ? err.response.data.message : err.message}`,
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
          }
        }
      }
    } catch (error) {
      addToast(
        `Módulo ${data.moduleName} não encontrado no produto EasyCloud. Este módulo não será cadastrado para o cliente, verifique.`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  }

  async function registerModulesEasyCloudProduct(clientId: string, modules: string[]): Promise<boolean> {
    let responseFindClientProduct: any;

    try {
      responseFindClientProduct = await api.get(`/clientProducts`, {
        params: {
          cliente: clientId,
          produtoDescricao: 'EASY CLOUD',
        }
      });
    } catch (error) {
      addToast(
        `Não foi encontrado o produto EASY CLOUD cadastrado para o cliente, verifique`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );

      return false;
    }

    try {
      const responseFindProductEasyCloud = await api.get(`/products`, {
        params: {
          descricao: 'EASY CLOUD',
        }
      });


      if (responseFindClientProduct.data.length > 0) {
        for await (const moduleName of modules) {
          await registerProductModule({
            clientProductId: responseFindClientProduct.data[0].id,
            moduleName,
            productId: responseFindProductEasyCloud.data[0].id,
          })
        }
      }
    } catch (err: any) {
      addToast(
        `Houve um problema ao cadastrar os módulos do produto EasyCloud. ${err.response ? err.response.data.message : err.message}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );

      return false;
    }

    return true;
  }

  async function registerEasyCloudProduct(clientId: string): Promise<boolean> {
    try {
      const responseProduct = await api.get(`/products`, {
        params: {
          descricao: 'EASY CLOUD',
        }
      });

      const clientProduct = {
        cliente: clientId,
        produto: responseProduct.data[0].id,
      };

      await api.post(`/clientProducts`, clientProduct);
    } catch (err: any) {
      addToast(
        `Houve um problema ao cadastrar o produto EasyCloud. ${err.response ? err.response.data.message : err.message}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );

      return false;
    }

    return true;
  }

  async function easyCloudProductIsRegistered(clientId: string): Promise<boolean> {
    try {
      const responseFindProductEasyCloud = await api.get(`/clientProducts`, {
        params: {
          cliente: clientId,
          produtoDescricao: 'EASY CLOUD',
        }
      });

      return responseFindProductEasyCloud.data.length > 0;
    } catch (err: any) {
      // addToast(
      //   `Houve um problema ao consultar se o produto EasyCloud está cadastrado para o cliente. ${err.response ? err.response.data.message : err.message}`,
      //   {
      //     appearance: 'error',
      //     autoDismiss: true,
      //   },
      // );

      return false;
    }
  }

  async function handleSituation(id: string, situation: string, cpfCnpj: string) {
    let clientReponse: AxiosResponse<any> | undefined;
    try {
      clientReponse = await api.get(`/clients`, {
        params: {
          cpfCnpj
        }
      });
    } catch (err) {
      console.log(err)
    }

    const responseCompany = await apiEasyCloudGerencial.get('/companies', {
      auth: {
        username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
        password: String(
          process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
        ),
      },
      params: {
        id,
      },
    });

    const companyData = responseCompany.data[0];

    const modules: string[] = [];

    if (companyData.nfe == 1) {
      modules.push('NF-e')
    }
    if (companyData.nfce == 1) {
      modules.push('NFC-e')
    }
    if (companyData.nfse == 1) {
      modules.push('NFS-e')
    }
    if (companyData.boleto == 1) {
      modules.push('Boleto')
    }
    if (companyData.consultaNotaDestinada == 1) {
      modules.push('Consulta notas destinadas')
    }
    if (companyData.tefSmartPOS == 1) {
      modules.push('TEF Smart POS')
    }
    if (companyData.pix == 1) {
      modules.push('Pix')
    }

    const activateClient = situation === 'Ativo' ? false : true;

    try {
      if (clientReponse && clientReponse.data?.length > 0) {
        const clientId = clientReponse.data[0].id;
        await api.patch(`/clients/${clientId}`, {
          dataInativo: situation === 'Ativo' ? new Date() : null,
        });

        if (activateClient) {
          if (!await easyCloudProductIsRegistered(clientId)) {
            if (!await registerEasyCloudProduct(clientId)) {
              return;
            }
          }

          if (!await registerModulesEasyCloudProduct(clientId, modules)) {
            return false;
          }
        }
      } else {
        let cidadeId;
        if (companyData.cidades) {
          const cityResponse = await api.get(`/cities`, {
            params: {
              nomeCompleto: companyData.cidades.nome
            }
          });

          if (cityResponse.data?.length > 0) {
            cidadeId = cityResponse.data[0].id
          } else {
            addToast(
              `Não foi encontrado a cidade ${companyData.cidades.nome} no sistema gerencial, ela deve ser cadastrada.`,
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
            return;
          }
        } else {
          addToast(
            `Não foi encontrado a cidade ${companyData.cidades.nome} no sistema gerencial, ela deve ser cadastrada.`,
            {
              appearance: 'error',
              autoDismiss: true,
            },
          );
          return;
        }

        const clientTypeResponse = await api.get(`/clientTypes`, {
          params: {
            descricaoCompleta: 'COMÉRCIO EM GERAL'
          }
        });

        const responseInvoiceSettings = await api.get(`/invoiceSettings`, {
          params: {
            descricaoCompleta: 'CONTA JURIDICA',
          }
        });

        const idContaJuridica = responseInvoiceSettings.data[0].id;

        const insertCompanyData = {
          dataInativo: situation === 'Ativo' ? new Date() : null,
          razaoSocial: companyData.razaoSocial,
          fantasia: companyData.fantasia,
          cpfCnpj: companyData.cpfCnpj,
          rgIe: companyData.rgIe !== null && companyData.rgIe !== undefined ? companyData.rgIe : 'ISENTO',
          im: companyData.im,
          logradouro: companyData.logradouro,
          numero: companyData.numero,
          complemento: companyData.complemento,
          bairro: companyData.bairro,
          cep: companyData.cep,
          cidade: cidadeId,
          telefone1: companyData.telefone,
          email1: companyData.email,
          email3: companyData.email,
          tipo: clientTypeResponse.data[0].id,
          diaVencimento: 10,
          naoReajustaMensalidade: 0,
          nfse: 1,
          naoSomaTarifaMensalidade: 0,
          contaCobranca: idContaJuridica,
        };

        const responseClient = await api.post(`/clients`, insertCompanyData);

        if (activateClient) {
          if (!await easyCloudProductIsRegistered(responseClient.data.id)) {
            if (!await registerEasyCloudProduct(responseClient.data.id)) {
              return;
            }
          }

          if (!await registerModulesEasyCloudProduct(responseClient.data.id, modules)) {
            return false;
          }
        }
      }

      await apiEasyCloudGerencial.patch(`/companies/situation/${id}`, {
        situacao: situation === 'Ativo' ? 'Inativo' : 'Ativo',
        dataInativo: situation === 'Ativo' ? new Date() : null,
      }, {
        auth: {
          username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
          password: String(
            process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
          )
        }
      }
      );

      addToast('Situação da empresa alterada com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });

      loadRegisters();
    } catch (err: any) {
      addToast(
        err.response
          ? err.response.data.message
          : `Problemas ao ${situation === 'Ativo' ? 'inativar' : 'ativar'} a empresa, tente novamente`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  }

  return (
    <Container>
      <Title>
        <h1>Gerenciamento do Easy Cloud</h1>
      </Title>
      <Buttons>
        <Button width={230} visual="secondary" onClick={handleRunMigrations}>
          <FaSyncAlt size={18} color="#1362f5" />
          Executar pendências
        </Button>
        <Button width={230} visual="secondary" onClick={handleGenerateBackups}>
          <FaSyncAlt size={18} color="#1362f5" />
          Gerar Backups
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersField size="10%">
            <input
              value={codigo}
              onChange={e => setCodigo((e.target.value as any) || '')}
              id="codigo"
              placeholder="Filtre pelo código"
            />
          </FiltersField>
          <FiltersField size="10%">
            <DatePicker
              error={false}
              onChange={(e: any) => {
                setDataIncial(e);
              }}
              placeholderText="Data inicial"
              selected={dataInicial}
            />
          </FiltersField>
          <FiltersField size="10%">
            <DatePicker
              error={false}
              onChange={(e: any) => {
                setDataFinal(e);
              }}
              placeholderText="Data final"
              selected={dataFinal}
            />
          </FiltersField>
          <FiltersField size="27%">
            <input
              value={nome}
              onChange={e => setNome((e.target.value as any) || '')}
              id="nome"
              placeholder="Filtre pelo nome"
            />
          </FiltersField>
          <FiltersField size="13%">
            <FiltersFieldSwitch>
              <h3>Inativos</h3>
              <Switch
                checked={inativos}
                onChange={() => setInativos(!inativos)}
              />
            </FiltersFieldSwitch>
          </FiltersField>
          <FiltersField size="30%">
            <Select
              name="cidades"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelas cidades"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setCidades(e.label);
                } else {
                  setCidades('');
                }
              }}
              defaultValue={cidades}
              options={selectCidades}
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        {loading ? (
          <FormLoading>
            <ClipLoader size={30} color="#FFFFFF" loading={loading} />
          </FormLoading>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>
                      Cod.
                      <button
                        onClick={() => {
                          orderBy('codigo');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('codigo', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('codigo', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Data Inclusão
                      <button
                        onClick={() => {
                          orderBy('dataInclusao');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('dataInclusao', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('dataInclusao', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Razão social
                      <button
                        onClick={() => {
                          orderBy('razaoSocial');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('razaoSocial', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('razaoSocial', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Fantasia
                      <button
                        onClick={() => {
                          orderBy('fantasia');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('fantasia', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('fantasia', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Cidade
                      <button
                        onClick={() => {
                          orderBy('cidade');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('cidade', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('cidade', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Situação
                    </div>
                  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {'id' in entity[0] &&
                  entity
                    .slice(handlePagination(1), handlePagination(2))
                    .map((e, index) => (
                      <tr key={index}>
                        <TableTd width="7%">
                          {e.codigo}
                        </TableTd>
                        <TableTd width="10%">
                          {e.dataInclusao && manipulateDates(e.dataInclusao)}
                        </TableTd>
                        <TableTd width="20%">
                          {e.razaoSocial}
                        </TableTd>
                        <TableTd width="25%">
                          {e.fantasia}
                        </TableTd>
                        <TableTd width="20%">
                          {e.cidade}
                        </TableTd>
                        <TableTd width="7%">
                          {
                            e.situacao === 'Ativo' ? (
                              <TableTdCard>
                                <FiUserCheck size={15} color="#52BE80" />
                                Ativo
                              </TableTdCard>
                            ) : e.situacao === 'Inativo' ? (
                              <TableTdCard>
                                <FiUserMinus size={15} color="#EC7063" />
                                Inativo
                              </TableTdCard>
                            ) : (
                              <TableTdCard>
                                <FiUser size={15} color="#F4D03F" />
                                Pendente
                              </TableTdCard>
                            )
                          }
                        </TableTd>
                        <TableTd center width="8%">
                          <button
                            type="button"
                            title={e.situacao === 'Ativo' ? 'Inativar' : 'Ativar'}
                            onClick={() => {
                              confirmation(
                                () => handleSituation(e.id || '', e.situacao || '', e.cpfCnpj || ''),
                                `Deseja alterar o status da empresa para ${e.situacao === 'Ativo' ? 'INATIVO' : 'ATIVO'} ?`,
                                'abort',
                              );
                            }}
                          >
                            {
                              e.situacao === 'Ativo' ?
                                (<FiX size={20} color="#Fafafa" />) :
                                (<FiCheck size={20} color="#Fafafa" />)
                            }
                          </button>
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiSearch size={20} color="#Fafafa" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
              </tbody>
            </table>
          </>
        )}
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default ManagementsCompanies;
