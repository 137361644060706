import styled from 'styled-components';

interface FormFieldInputProps {
  error?: boolean;
  height?: string;
  color?: string;
}

export const FormFieldInputPayed = styled.input<FormFieldInputProps>`
  width: 100%;
  height: 40px;
  background: #2e2c2c;
  border-radius: 8px;
  border: ${props => (props.error ? '2px solid #1362f5' : '0')};
  color: ${props => (props.color ? props.color : '#d1cdcb')};
  font-weight: bold;
  font-size: 14px;
  padding-left: 8px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a39f9d;
    font-weight: bold;
    font-size: 14px;
  }
`;
