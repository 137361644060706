/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { parseISO } from 'date-fns';

interface Form {
  [key: string]: string;
}

export function changeForm(object: any, field: string, value: string): Form {
  const obj: any = {};
  for (const key in object) {
    obj[key] = object[key];
  }
  obj[field] = value;
  return obj;
}

export function saveForm(object: any, floats: string[]): Form {
  const obj: any = {};
  for (const key in object) {
    if (object[key] === '') {
      obj[key] = null;
    } else if (floats.includes(key)) {
      if (
        object[key] === null ||
        object[key] === '' ||
        object[key] === undefined
      ) {
        obj[key] = null;
      } else {
        obj[key] =
          String(object[key]).indexOf(',') > 0
            ? object[key].replace(',', '.')
            : object[key];
      }
    } else {
      obj[key] = object[key];
    }
  }
  return obj;
}

export function loadForm(
  object: any,
  floats: string[],
  dates: string[],
  params: string[],
): Form {
  const obj: any = {};
  for (const key in object) {
    if (floats.includes(key)) {
      if (object[key] === null || object[key] === '') {
        obj[key] = null;
      } else if (String(object[key]).indexOf('.') > 0) {
        obj[key] = String(object[key].toFixed(2)).replace('.', ',');
      } else {
        obj[key] = `${object[key]},00`;
      }
    } else if (dates.includes(key)) {
      if (object[key] !== null) {
        obj[key] = parseISO(object[key]);
      } else {
        obj[key] = null;
      }
    } else if (params.includes(key)) {
      if (object[key] === null || object[key] === '') {
        obj[key] = 0;
      } else {
        obj[key] = object[key];
      }
    } else if (object[key] === null) {
      obj[key] = '';
    } else {
      obj[key] = object[key];
    }
  }
  return obj;
}
