import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';

import { moneyMask, mesAnoMask } from '../../../utils/handleMasks';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  cliente: string;
  descricao: string;
  mesAno: string;
  mesAnoFinal: string;
  valor: number;
  tipo: string;
  produto: string;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

interface SelectData1 {
  id: string;
  descricao: string;
}

const ReceiptDCRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idReceiptDC } = useParams<{ idReceiptDC: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    cliente: '',
    descricao: '',
    mesAno: '',
    mesAnoFinal: '',
    valor: 0,
    tipo: 'C',
    produto: '',
  });

  const [selectClients, setSelectClients] = useState([]);
  const [selectClientProducts, setSelectClientProducts] = useState([]);

  const [selectAcrescimoDesconto, setSelectAcrescimoDesconto] = useState<
    SelectData[]
  >([]);

  const [entityValidation] = useState({
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    produto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Produto'),
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    mesAno: Yup.string()
      .required('Preenchimento obrigatório')
      .matches(/(0[1-9]|1[012])[/](20)\d{2}/, `Inválido`)
      .label(`Mês/Ano${idReceiptDC === undefined ? ' Inicial' : ''}`),
    mesAnoFinal: Yup.string().nullable().label('Mês/Ano Final').test(
      'Verifica se é inclusão',
      (value: any, { createError, path }) => {
        let hasError = false;
        let message = '';
        if (idReceiptDC === undefined && !String(value).match(/(0[1-9]|1[012])[/](20)\d{2}/)) {
          hasError = true;
          message = 'Inválido';
        }
        if (hasError) {
          return createError({
            path,
            message,
          })
        }
        return true;
      }
    ),
    valor: Yup.string().required('Preenchimento obrigatório').label('Valor'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadProducts() {
      if (entity.cliente) {
        const responseClientProduct = await api.get('/clientProducts', {
          params: {
            cliente: entity.cliente,
            status: 'ativo',
          },
        });

        const dataSelect: SelectData1[] = [];
        responseClientProduct.data.map((item: any) =>
          dataSelect.push({
            id: item.produto,
            descricao: item.produtos.descricao,
          }),
        );

        setSelectClientProducts(
          changeNamesOfColumns(dataSelect, 'descricao', 'id'),
        );

      } else {
        setSelectClientProducts([]);
      }
    }

    loadProducts();
  }, [entity.cliente])

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/clients', {
          params: {
            status: 'ativo',
          },
        });
        setSelectClients(
          changeNamesOfColumns(response.data, 'razaoSocial', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }

      setSelectAcrescimoDesconto([
        {
          label: 'DESCONTO',
          value: 'D',
        },
        {
          label: 'ACRÉSCIMO',
          value: 'C',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idReceiptDC !== undefined) {
        const response = await api.get(`/receiptsDC/${idReceiptDC}`);
        const entityManipulated: any = loadForm(
          response.data,
          ['valor'],
          [],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idReceiptDC]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'money') {
      newValue = moneyMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, ['valor']);
        if (idReceiptDC === undefined) {
          await api.post('/receiptsDC', entitySave);
        } else {
          delete entitySave.mesAnoFinal;

          await api.put(`/receiptsDC/${idReceiptDC}`, entitySave);
        }
        addToast('Acréscimo/Desconto gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar o acréscimo/desconto, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Acréscimos e Descontos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="55%">
              <span>
                {`Cliente *
                ${errors.cliente ? `(${errors.cliente})` : ''}
                `}
              </span>
              <Select
                name="cliente"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o cliente"
                onBlur={(e: any) => handleValidation('cliente')}
                onChange={(e: any) => {
                  handleChanges('cliente', e.value);
                }}
                error={!!errors.cliente}
                defaultValue={entity.cliente}
                options={selectClients}
              />
            </FormField>
            <FormField size="45%">
              <span>
                {`Produto *
                ${errors.produto ? `(${errors.produto})` : ''}
                `}
              </span>
              <Select
                name="produto"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o produto"
                onBlur={(e: any) => handleValidation('produto')}
                onChange={(e: any) => {
                  handleChanges('produto', e.value);
                }}
                error={!!errors.produto}
                defaultValue={entity.produto}
                options={selectClientProducts}
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size={idReceiptDC === undefined ? '40%' : '55%'}>
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`
                Mês/Ano ${idReceiptDC === undefined ? 'Inicial ' : ''}*
                ${errors.mesAno ? `(${errors.mesAno})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, mesAnoMask(e.target.value));
                }}
                value={entity.mesAno}
                id="mesAno"
                autoComplete="off"
                maxLength={7}
                error={!!errors.mesAno}
                placeholder={`Digite o mês/ano${idReceiptDC === undefined ? ' inicial' : ''}`}
              />
            </FormField>

            {
              idReceiptDC === undefined && (
                <FormField size="15%">
                  <span>
                    {`
                Mês/Ano Final *
                ${errors.mesAnoFinal ? `(${errors.mesAnoFinal})` : ''}
                `}
                  </span>
                  <FormFieldInput
                    onBlur={e => handleValidation(e.target.id)}
                    onChange={e => {
                      handleChanges(e.target.id, mesAnoMask(e.target.value));
                    }}
                    value={entity.mesAnoFinal}
                    id="mesAnoFinal"
                    autoComplete="off"
                    maxLength={7}
                    error={!!errors.mesAnoFinal}
                    placeholder="Digite o mês/ano final"
                  />
                </FormField>
              )
            }

            <FormField size="15%">
              <span>
                {`Valor
                ${errors.valor ? `(${errors.valor})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'money');
                }}
                value={entity.valor ? entity.valor : ''}
                autoComplete="off"
                id="valor"
                error={!!errors.valor}
                placeholder="Digite o valor"
              />
            </FormField>
            <FormField size="15%">
              <span>Acréscimo/Desconto</span>
              <Select
                name="tipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre acréscimo e desconto"
                onChange={(e: any) => {
                  handleChanges('tipo', e.value);
                }}
                defaultValue={entity.tipo}
                options={selectAcrescimoDesconto}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ReceiptDCRegister;
