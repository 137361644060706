import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';

import {
  getIndividualErrors,
  getErrors,
} from '../../../../../utils/validateForm';
import {
  changeForm,
  saveForm,
  loadForm,
} from '../../../../../utils/handleForms';

import Confirmation from '../../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldTextArea,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  produtoVersao: string | undefined;
  descricao: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductVersionTaskRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProductVersion } = useParams<{ idProductVersion: string }>();
  const { idProductVersionTask } = useParams<{
    idProductVersionTask: string;
  }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    produtoVersao: idProductVersion,
    descricao: '',
  });

  const [entityValidation] = useState({
    produtoVersao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Versão'),
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadEntity() {
      if (idProductVersionTask !== undefined) {
        const response = await api.get(
          `/productVersionTasks/${idProductVersionTask}`,
        );
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idProductVersionTask]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idProductVersionTask === undefined) {
          await api.post('/productVersionTasks', entitySave);
          addToast('Tarefa inserida com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          await api.put(
            `/productVersionTasks/${idProductVersionTask}`,
            entitySave,
          );
          addToast('Tarefa editada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar a tarefa, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de tarefas de versões de produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine height="350px">
            <FormField size="100%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldTextArea
                height="320px"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.descricao}
                autoComplete="off"
                id="descricao"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductVersionTaskRegister;
