import styled from 'styled-components';
import { shade } from 'polished';

export const FileButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`;

export const FileButtonUpload = styled.label`
  background: #1362f5;
  color: #ffffff;
  border: 0;
  margin-right: 6px;
  height: 35px;
  width: 130px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s;
  &:hover {
    background: ${props => shade(0.2, '#1362f5')};
  }
  svg {
    margin-right: 4px;
  }
`;

export const FileButtonClear = styled.button`
  background: #ffffff;
  color: #1362f5;
  border: 0;
  height: 35px;
  width: 130px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s;
  &:hover {
    background: ${props => shade(0.2, '#ffffff')};
  }
  svg {
    margin-right: 4px;
  }
`;

export const FileList = styled.div`
  height: 185px;
  background: #2e2c2c;
  padding: 10px;
  border-radius: 8px;
  p {
    font-weight: bold;
    color: #d1cdcb;
  }
`;
