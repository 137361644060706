import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaCopy, FaPlus, FaSearch } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import { format, parseISO } from 'date-fns';
import Switch from 'react-switch';
import { TailSpin } from 'react-loader-spinner'; 

import api from '../../../services/api';

import { useAuth } from '../../../hooks/AuthContext';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldWithSearch,
  FormFieldInput,
  FormFieldInfo,
  FormTitle,
  FormTable,
  FormTableLegend,
  FormFieldTextArea,
  Table,
  TableTd,
  Button,
  TableHeader,
  TableHeaderColumn,
  Container,
  FormButtons,
} from '../../../styles/registers';

import { LogReasonContainer } from './styles';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  created_at: Date | null;
  cliente: string;
  clienteProduto: string;
  descricao: string;
  status: string;
  prioritaria: number;
  observacaoTarefa: string;
  observacaoAtualizacao: string;
  criarPasta: number;
}

interface EntityTopicsData {
  id?: string;
  tarefa?: string;
  titulo?: string;
  colaborador?: string;
  concluido?: number;
}

interface EntityLogsData {
  id?: string;
  tarefa?: string;
  status?: string;
  colaborador?: string;
  motivo?: string;
  created_at?: Date;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  descricao: string;
  id: string;
}

const TaskRegister: React.FC = () => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { idTask } = useParams<{ idTask: string }>();
  const { addToast } = useToasts();
  const [idTaskState, setIdTaskState] = useState(idTask);
  const [loadConclusionState, setLoadConclusionState] = useState<string>('0');
  const [filesLocation, setFilesLocation] = useState<string>('');
  const [logReason, setLogReason] = useState<string | null>(null);
  const [showLogReason, setShowLogReason] = useState(false);
  const [logReasonError, setLogReasonError] = useState(false);

  const [entity, setEntity] = useState<EntityData>({
    created_at: null,
    cliente: '',
    clienteProduto: '',
    descricao: '',
    status: 'PENDENTE',
    prioritaria: 0,
    observacaoTarefa: '',
    observacaoAtualizacao: '',
    criarPasta: 1,
  });

  const [entityTopics, setEntityTopics] = useState<EntityTopicsData[]>([{}]);
  const [entityLogs, setEntityLogs] = useState<EntityLogsData[]>([{}]);

  const [entityValidation] = useState({
    created_at: Yup.date().nullable(),
    cliente: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Cliente'),
    clienteProduto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Produto'),
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    status: Yup.string().required('Preenchimento obrigatório').label('Status'),
    prioritaria: Yup.number()
      .required('Preenchimento obrigatório')
      .label('Prioritária'),
    observacaoTarefa: Yup.string(),
    observacaoAtualizacao: Yup.string(),
    criarPasta: Yup.number(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const [initialStatus, setInitialStatus] = useState<string>();
  const [collaboratorSigned, setCollaboratorSigned] = useState<string>();

  const [selectClients, setSelectClients] = useState([]);
  const [selectClientProducts, setSelectClientProducts] = useState([]);
  const [selectStatus, setSelectStatus] = useState<any[]>([]);

  const loadTopicsEntity = useCallback(async () => {
    if (!idTaskState) return;

    try {
      const response = await api.get('/taskTopics', {
        params: {
          tarefa: idTaskState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          tarefa: item.tarefa,
          titulo: item.titulo,
          colaborador: item.colaboradores.nome,
          concluido: item.concluido,
        };
      });
      setEntityTopics(responseDisassembled);
    } catch (error) {
    }
  }, [idTaskState]);

  const loadLogsEntity = useCallback(async () => {
    if (!idTaskState) return;

    try {
      const response = await api.get('/taskLogs', {
        params: {
          tarefa: idTaskState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          tarefa: item.tarefa,
          status: item.status,
          colaborador: item.colaboradores.nome,
          created_at: format(parseISO(item.created_at), 'dd/MM/yyyy HH:mm'),
          motivo: item.motivo,
        };
      });
      setEntityLogs(responseDisassembled);
    } catch (error) {
    }
  }, [idTaskState]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const responseClient = await api.get('/clients', {
          params: {
            status: 'ativo',
          },
        });
        setSelectClients(
          changeNamesOfColumns(responseClient.data, 'fantasia', 'id'),
        );

        if (entity.cliente) {
          const responseClientProduct = await api.get('/clientProducts', {
            params: {
              cliente: entity.cliente,
              status: 'ativo',
            },
          });

          const dataSelect: SelectData[] = [];
          responseClientProduct.data.map((item: any) =>
            dataSelect.push({
              id: item.id,
              descricao: item.produtos.descricao,
            }),
          );

          setSelectClientProducts(
            changeNamesOfColumns(dataSelect, 'descricao', 'id'),
          );

          const responseObsClient = await api.get(`/clients/${entity.cliente}`);
          if (
            responseObsClient.data.obsAtualizacao &&
            entity.observacaoTarefa === ''
          ) {
            handleChanges(
              'observacaoTarefa',
              responseObsClient.data.obsAtualizacao,
            );
          }
        } else {
          setSelectClientProducts([]);
        }

        setSelectStatus([
          {
            value: 'CANCELADA',
            label: 'CANCELADA',
          },
          {
            value: 'AGUARDANDO',
            label: 'AGUARDANDO',
          },
          {
            value: 'PENDENTE',
            label: 'PENDENTE',
          },
          {
            value: 'EM ANDAMENTO',
            label: 'EM ANDAMENTO',
          },
          {
            value: 'CONCLUÍDA',
            label: 'CONCLUÍDA',
          },
          {
            value: 'ATUALIZADA',
            label: 'ATUALIZADA',
          },
        ]);
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadAuxiliarVariables() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        setCollaboratorSigned(response.data[0].id);
      } catch (err) {
        //
      }
    }
    loadAuxiliarVariables();

    async function loadEntity() {
      if (idTaskState !== undefined) {
        const response = await api.get(`/tasks/${idTaskState}`);
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['created_at'],
          [],
        );

        setInitialStatus(entityManipulated.status);

        delete entityManipulated.id;
        delete entityManipulated.updated_at;

        setFilesLocation(`c:\\3rSistema1\\TarefasGerencial\\${idTaskState}`);

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    loadTopicsEntity();
    loadLogsEntity();
  }, [idTaskState, loadTopicsEntity, loadLogsEntity, user, entity.cliente]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (showLogReason && logReason === null) {
      validation.b = false;
      setLogReasonError(true);
    } else {
      setLogReasonError(false);
    }

    if (validation.b) {
      try {
        if (entity.status === 'CONCLUÍDA' || entity.status === 'ATUALIZADA') {
          let validationConclude = true;
          entityTopics.map(e => {
            if (!e.concluido) validationConclude = false; // return false;
            return true;
          });
          if (!validationConclude) {
            addToast('Existem tópicos não concluídos, verifique!', {
              appearance: 'error',
              autoDismiss: true,
            });
            return;
          }
        }

        const entitySave = saveForm(entity, []);
        delete entitySave.created_at;
        if (idTaskState === undefined) {
          const response = await api.post('/tasks', entitySave);
          setIdTaskState(response.data.id);

          setInitialStatus(entitySave.status);
          await api.post('/taskLogs', {
            status: entitySave.status,
            tarefa: response.data.id,
            colaborador: collaboratorSigned,
            motivo: logReason,
          });
          loadLogsEntity();

          setFilesLocation(
            `c:\\3rSistema1\\TarefasGerencial\\${response.data.id}`,
          );
          navigate(`/tasks/register/${response.data.id}`, { replace: true });
          addToast(
            'Tarefa inserida com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
          document.getElementById('container')?.scrollTo(0, 0);
        } else {
          await api.put(`/tasks/${idTaskState}`, entitySave);

          if (initialStatus !== entitySave.status) {
            await api.post('/taskLogs', {
              status: entitySave.status,
              tarefa: idTaskState,
              colaborador: collaboratorSigned,
              motivo: logReason,
            });
          }

          navigate(-1);
          addToast('Tarefa editada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar a tarefa, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleTopicDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/taskTopics/${id}`);
          setEntityTopics([{}]);
          loadTopicsEntity();
          addToast('Tópico deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o tópico, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o tópico ?',
      'delete',
    );
  }

  async function handleTopicConclusion(id: string, concluido: number) {
    try {
      setLoadConclusionState(id);
      const { data }: any = await api.get(`/taskTopics/${id}`);
      await api.put(`/taskTopics/${id}`, {
        tarefa: data.tarefa,
        titulo: data.titulo,
        colaborador: data.colaborador,
        descicao: data.descicao,
        concluido: concluido === 1 ? 0 : 1,
      });
    } catch (err) {
      addToast(
        `Problemas ao ${concluido === 0 ? 'concluir' : 'desfazer conclusão do'
        } tópico, tente novamente`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
    setLoadConclusionState('0');
    loadTopicsEntity();
  }

  function handleSecondaryEdit(page: string, id?: string) {
    navigate(`/tasks/register/${idTask}/${page}/${id}`);
  }

  function handleSecondaryInsert(page: string) {
    navigate(`/tasks/register/${idTaskState}/${page}`);
  }

  function handleLogReason(status: string) {
    if (status === 'CANCELADA' || status === 'AGUARDANDO') {
      setShowLogReason(true);
    } else {
      setLogReason(null);
      setShowLogReason(false);
    }
  }

  function handleCopy() {
    const copyText: any = document.getElementById('filesLocation');
    copyText?.removeAttribute('disabled');
    copyText?.select();
    copyText?.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText?.setAttribute('disabled', 'disabled');
    addToast('Copiado para a área de transferência', {
      appearance: 'info',
      autoDismiss: true,
    });
  }

  return (
    <Container id="container">
      <Title>
        <h1>Cadastro de Tarefas</h1>
      </Title>
      <Form>

        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="50%">
              <span>
                {`
                Cliente *
                ${errors.cliente ? `(${errors.cliente})` : ''}
                `}
              </span>
              <div>
                <Select
                  name="cliente"
                  height={40}
                  maxMenuHeight={300}
                  placeholder="Escolha o cliente"
                  onBlur={(e: any) => handleValidation('cliente')}
                  onChange={(e: any) => {
                    handleChanges('cliente', e.value);
                  }}
                  error={!!errors.cliente}
                  defaultValue={entity.cliente}
                  options={selectClients}
                />
                <button
                  onClick={() => {
                    if (entity.cliente) {
                      navigate(`/clients/register/${entity.cliente}`);
                    } else {
                      addToast('Nenhum cliente informado, verifique!', {
                        appearance: 'error',
                        autoDismiss: true,
                      });
                    }
                  }}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="25%">
              <span>
                {`
                Produto *
                ${errors.clienteProduto ? `(${errors.clienteProduto})` : ''}
                `}
              </span>
              <Select
                name="clienteProduto"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o produto"
                onBlur={(e: any) => handleValidation('clienteProduto')}
                onChange={(e: any) => {
                  handleChanges('clienteProduto', e.value);
                }}
                error={!!errors.clienteProduto}
                defaultValue={entity.clienteProduto}
                options={selectClientProducts}
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`
                Status *
                ${errors.status ? `(${errors.status})` : ''}
                `}
              </span>
              <Select
                name="status"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o status"
                onBlur={(e: any) => handleValidation('status')}
                onChange={(e: any) => {
                  handleLogReason(e.value);
                  handleChanges('status', e.value);
                }}
                error={!!errors.status}
                defaultValue={entity.status}
                options={selectStatus}
              />
            </FormField>
            <FormField size="10%">
              <span>Data</span>
              <DatePicker
                error={!!errors.created_at}
                onChange={(e: any) => {
                  return null;
                }}
                placeholderText={String(format(new Date(), 'dd/MM/yyyy'))}
                selected={entity.created_at}
                disabled
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="90%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="10%">
              <span>Prioritária</span>
              <Switch
                checked={!!entity.prioritaria}
                onChange={() => {
                  handleChanges(
                    'prioritaria',
                    entity.prioritaria === 0 ? 1 : 0,
                  );
                }}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTable height="350px">
            <div>
              <h1>LISTA DE TÓPICOS</h1>
              <div>
                <FormTableLegend color="#668B8B">Concluído</FormTableLegend>
                <button
                  disabled={idTaskState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('topic');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="7%">Concluído</TableHeaderColumn>
              <TableHeaderColumn width="63%">Título</TableHeaderColumn>
              <TableHeaderColumn width="20%">Colaborador</TableHeaderColumn>
            </TableHeader>
            <Table height="250px">
              <table>
                <tbody>
                  {'id' in entityTopics[0] &&
                    entityTopics.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="7%">
                          {loadConclusionState === e.id ? (
                            <TailSpin
                              // type="ThreeDots"
                              color="#1362f5"
                              height={40}
                              width={40}
                            />
                          ) : (
                            <Switch
                              height={20}
                              width={40}
                              handleDiameter={22}
                              checked={!!e.concluido}
                              onChange={() => {
                                handleTopicConclusion(
                                  e.id || '0',
                                  e.concluido || 0,
                                );
                              }}
                            />
                          )}
                        </TableTd>
                        <TableTd
                          color={e.concluido ? '#668B8B' : '#888888'}
                          width="63%"
                        >
                          {e.titulo}
                        </TableTd>
                        <TableTd
                          color={e.concluido ? '#668B8B' : '#888888'}
                          width="20%"
                        >
                          {e.colaborador}
                        </TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('topic', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTopicDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTitle>ARQUIVOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="100%">
              <div>
                <FormFieldInfo
                  disabled
                  value={filesLocation}
                  id="filesLocation"
                  placeholder="Aguardando inclusão"
                />
                <button type="button" onClick={() => handleCopy()}>
                  <FaCopy size={18} color="#fafafa" />
                </button>
              </div>
            </FormFieldWithSearch>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>OBSERVAÇÕES</FormTitle>
          <FormLine height="180px">
            <FormField size="100%">
              <span>Descrição geral da tarefa</span>
              <FormFieldTextArea
                height="140px"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.observacaoTarefa}
                autoComplete="off"
                id="observacaoTarefa"
                error={!!errors.observacaoTarefa}
                placeholder="Digite a descrição geral da tarefa"
              />
            </FormField>
          </FormLine>
          <FormLine height="180px">
            <FormField size="100%">
              <span>Descrição para atualizações</span>
              <FormFieldTextArea
                height="140px"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.observacaoAtualizacao}
                autoComplete="off"
                id="observacaoAtualizacao"
                error={!!errors.observacaoAtualizacao}
                placeholder="Digite a descrição geral para atualizações"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          {showLogReason && (
            <LogReasonContainer>
              <FormField size="100%">
                <p>{`Motivo * (Tarefas com o status ${entity.status} necessitam deste preenchimento)`}</p>
                <FormFieldInput
                  onBlur={e => {
                    // eslint-disable-next-line no-unused-expressions
                    e.target.value === ''
                      ? setLogReasonError(true)
                      : setLogReasonError(false);
                  }}
                  onChange={e => setLogReason(e.target.value)}
                  value={logReason || ''}
                  id="logReason"
                  autoComplete="off"
                  error={logReasonError}
                  placeholder="Digite o motivo"
                />
              </FormField>
            </LogReasonContainer>
          )}
          <FormTable height="320px">
            <div>
              <h1>LISTA DE LOGS</h1>
              <div />
            </div>
            <TableHeader>
              <TableHeaderColumn width="15%">Data / Hora</TableHeaderColumn>
              <TableHeaderColumn width="12%">Status</TableHeaderColumn>
              <TableHeaderColumn width="15%">Colaborador</TableHeaderColumn>
              <TableHeaderColumn width="58%">Motivo</TableHeaderColumn>
            </TableHeader>
            <Table height="200px">
              <table>
                <tbody>
                  {'id' in entityLogs[0] &&
                    entityLogs.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="15%">{String(e.created_at)}</TableTd>
                        <TableTd width="12%">{e.status}</TableTd>
                        <TableTd width="15%">{e.colaborador}</TableTd>
                        <TableTd width="58%">{e.motivo}</TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>


        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#faede8" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default TaskRegister;
