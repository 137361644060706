import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, endOfMonth, startOfMonth } from 'date-fns';
import { useToasts } from 'react-toast-notifications';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaRandom,
} from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { TailSpin } from 'react-loader-spinner';

import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import Switch from 'react-switch';

import api from '../../services/api';

import {
  manipulateDates,
  orderBy as handleOrderBy,
  manipulateDateHour,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  Tables,
  TableTd,
  TablesPagination,
  ButtonLink,
} from '../../styles/lists';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import { FilterButtonDiv, FilterContentDiv, FilterDiv, CustomFiltersLine, FiltersFieldBetween } from './styles';
import FilterButton from '../../components/FilterButton';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  dataHora?: string;
  produto?: string;
  produtoVersao?: string;
  cliente?: string;
  descricao?: string;
  visualizado?: number;
}

const Failure: React.FC = () => {
  const navigate = useNavigate();

  const { addToast } = useToasts();
  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'data',
    desc: true,
  });
  const [loadViewedState, setLoadViewedState] = useState<string>('0');

  const filters: any = getFilters('failures');

  const [produto, setProduto] = useState(
    Object.keys(filters).length > 0 ? filters.produto : '',
  );
  const [produtoVersao, setProdutoVersao] = useState(
    Object.keys(filters).length > 0 ? filters.produtoVersao : '',
  );
  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : startOfMonth(new Date()),
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : endOfMonth(new Date()),
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );

  const [selectStatus, setSelectStatus] = useState([
    {
      label: 'NÃO VISUALIZADO',
      value: 0,
    },
    {
      label: 'VISUALIZADO',
      value: 1,
    }
  ]);

  const [status, setStatus] = useState<[]>(
    Object.keys(filters).length > 0
      ? filters.status
      : [{ label: 'NÃO VISUALIZADO', value: 0 }],
  );

  function saveFilters() {
    setFilters(
      {
        produto,
        produtoVersao,
        cliente,
        dataInicial,
        dataFinal,
        descricao,
        status,
      },
      'failures',
    );
  }

  const [selectProducts, setSelectProducts] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      const response = await api.get('/products', {
        params: {
          status: 'ativo'
        }
      });
      setSelectProducts(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadProducts();
  }, []);

  async function loadRegisters() {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const statusManipulated: string[] = [];
        const statusLoop: any[] = status || [];
      
        statusLoop.map(item => {
          statusManipulated.push(String(item.value));
          return true;
        });

        const response = await api.get('/failures', {
          params: {
            productId: produto, 
            productVersionName: produtoVersao,
            clientName: cliente,
            startDate: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
            endDate: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
            description: descricao,
            visualizado: statusManipulated
          },
        });

        const responseDisassembled = await response.data.map((item: any) => {
          return {
            id: item.id,
            dataHora: item.dataHora,
            produto: item.produtoDados.descricao,
            produtoVersao: item.produtoVersaoDados.versao,
            cliente: item.clienteDados.fantasia,
            descricao:
              // eslint-disable-next-line no-nested-ternary
              item.descricao !== null
                ? item.descricao.length > 100 ? `${String(item.descricao).substring(0, 100)} ...` : item.descricao
                : '',
            visualizado: item.visualizado
          };
        });

        const responseOrdered: any = await handleOrderBy(
          responseDisassembled,
          order,
        );
        setEntity(responseOrdered);
        setPages(Math.ceil(response.data.length / 10));
        setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
      } catch (err) {
        setEntity([{}]);
        setPages(1);
        setActualPage(1);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(() => {
    loadRegisters()
  }, [status, dataInicial, dataFinal, produto, order]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/failures/register/${id}`);
  }

  async function handleFailureViewed(id: string, visualizado: number) {
    try {
      setLoadViewedState(id);
      const { data }: any = await api.get(`/failures/${id}`);
      await api.put(`/failures/${id}`, {
        visualizado: visualizado === 1 ? 0 : 1,
      });
    } catch (err) {
      addToast(
        `Problemas ao ${visualizado === 0 ? 'marcar visualização' : 'desmarcar visualização'
        } da falha, tente novamente`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
    setLoadViewedState('0');
    loadRegisters();
  }

  return (
    <Container>
      <Title>
        <h1>Ocorrência de Falhas</h1>
      </Title>
      <Filters>
        <FilterDiv>
          <FilterContentDiv>
            <CustomFiltersLine first>
              <FiltersField size="26%">
                <FiltersFieldBetween size="100%">
                  <div>
                    <DatePicker
                      error={false}
                      onChange={(e: any) => {
                        setDataIncial(e);
                      }}
                      placeholderText="Data inicial"
                      selected={dataInicial}
                    />
                  </div>
                  <h3>a</h3>
                  <div>
                    <DatePicker
                      error={false}
                      onChange={(e: any) => {
                        setDataFinal(e);
                      }}
                      placeholderText="Data final"
                      selected={dataFinal}
                    />
                  </div>
                </FiltersFieldBetween>
              </FiltersField>
              <FiltersField size="37%">
                <Select
                  name="produto"
                  height={40}
                  clear
                  maxMenuHeight={300}
                  placeholder="Filtre pelo produto"
                  onChange={(e: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (e !== null) {
                      setProduto(e.value);
                    } else {
                      setProduto('');
                    }
                  }}
                  defaultValue={produto}
                  options={selectProducts}
                />
              </FiltersField>
              <FiltersField size="37%">
                <input
                  value={produtoVersao}
                  onChange={e => setProdutoVersao((e.target.value as any) || '')}
                  id="produtoVersao"
                  placeholder="Filtre pela versão"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
            </CustomFiltersLine>
            <CustomFiltersLine second>
              <FiltersField size="26%">
                  <Select
                    name="status"
                    height={40}
                    clear
                    multiple
                    maxMenuHeight={300}
                    placeholder="Filtre pelo status"
                    onChange={(e: any) => {
                      setStatus(e);
                    }}
                    value={status}
                    options={selectStatus}
                  />
              </FiltersField>
              <FiltersField size="37%">
                <input
                  value={cliente}
                  onChange={e => setCliente((e.target.value as any) || '')}
                  id="cliente"
                  placeholder="Filtre pelo cliente"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
              <FiltersField size="37%">
                <input
                  value={descricao}
                  onChange={e => setDescricao((e.target.value as any) || '')}
                  id="descricao"
                  placeholder="Filtre pela descrição"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      loadRegisters();
                    }
                  }}
                />
              </FiltersField>
            </CustomFiltersLine>
          </FilterContentDiv>
          <FilterButtonDiv>
            <FilterButton
              tooltipDescription='Filtrar Falhas'
              onClick={loadRegisters}
            />
          </FilterButtonDiv>
        </FilterDiv>
      </Filters>
      
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Data
                  <button
                    onClick={() => {
                      orderBy('dataHora');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('dataHora', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('dataHora', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Produto
                  <button
                    onClick={() => {
                      orderBy('produto');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('produto', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('produto', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Versão
                  <button
                    onClick={() => {
                      orderBy('produtoVersao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('produtoVersao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('produtoVersao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Cliente
                  <button
                    onClick={() => {
                      orderBy('cliente');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cliente', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cliente', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Descrição
                  <button
                    onClick={() => {
                      orderBy('descricao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('descricao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('descricao', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Visualizado
                  <button
                    onClick={() => {
                      orderBy('visualizado');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('visualizado', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('visualizado', true)}
                    />
                  </button>
                </div></th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="10%">
                      {e.dataHora && manipulateDateHour(e.dataHora)}
                    </TableTd>
                    <TableTd width="10%">{e.produto}</TableTd>
                    <TableTd width="08%">{e.produtoVersao}</TableTd>
                    <TableTd width="25%">{e.cliente}</TableTd>
                    <TableTd width="32%">{e.descricao}</TableTd>
                    <TableTd width="05%">
                          {loadViewedState === e.id ? (
                            <TailSpin
                              // type="ThreeDots"
                              color="#1362f5"
                              height={40}
                              width={40}
                            />
                          ) : (
                            <Switch
                              height={20}
                              width={40}
                              handleDiameter={22}
                              checked={!!e.visualizado}
                              onChange={() => {
                                handleFailureViewed(
                                  e.id || '0',
                                  e.visualizado || 0,
                                );
                              }}
                            />
                          )}
                        </TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Failure;
