import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications';

import { FaPlus } from 'react-icons/fa';
import { FiEdit, FiSave, FiTrash, FiXCircle } from 'react-icons/fi';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';

import Confirmation from '../../../../components/Confirmation';
import DatePicker from '../../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormTable,
  TableHeader,
  TableHeaderColumn,
  Table,
  TableTd,
} from '../../../../styles/registers';
import { manipulateFloats } from '../../../../utils/handleLists';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  produto: string | undefined;
  descricao: string;
  dataInativo: Date | null;
}

interface EntityPriceData {
  id?: string;
  produtoModulo?: string;
  produtoTipo?: string;
  tipo?: string;
  usuariosAtivos?: string;
  quantidadeDocumentos?: string;
  quantidadeDocumentosExcedentes?: string;
  valorQuantidadeDocumentosExcedentes?: number;
  valor?: number;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductModuleRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProduct } = useParams<{ idProduct: string }>();
  const { idProductModule } = useParams<{ idProductModule: string }>();
  const { addToast } = useToasts();

  const [entityPrice, setEntityPrice] = useState<EntityPriceData[]>([
    {},
  ]);

  const [idProductModuleState, setIdProductModuleState] = useState(
    idProductModule,
  );

  const [entity, setEntity] = useState<EntityData>({
    produto: idProduct,
    descricao: '',
    dataInativo: null,
  });

  const [entityValidation] = useState({
    produto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Produto'),
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    dataInativo: Yup.date().nullable().label('Data de Inativação'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadPricesEntity = useCallback(async () => {
    if (!idProductModuleState) return;

    try {
      const response = await api.get('/productModulePrices', {
        params: {
          produtoModulo: idProductModuleState,
        },
      });

      console.log(response.data)

      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          produtoModulo: item.produtoModulo,
          produtoTipo: item.produtoTipos?.descricao,
          tipo: item.tipo,
          usuariosAtivos: item.usuariosAtivos,
          quantidadeDocumentos: item.quantidadeDocumentos,
          quantidadeDocumentosExcedentes: item.quantidadeDocumentosExcedentes,
          valorQuantidadeDocumentosExcedentes: manipulateFloats(item.valorQuantidadeDocumentosExcedentes),
          valor: manipulateFloats(item.valor),
        };
      });
      setEntityPrice(responseDisassembled);
    } catch (error) {}

  }, [idProductModuleState]);

  useEffect(() => {
    async function loadEntity() {
      if (idProductModuleState !== undefined) {
        const response = await api.get(`/productModules/${idProductModuleState}`);
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['dataInativo'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    loadPricesEntity();
  }, [idProductModuleState, loadPricesEntity]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handlePriceDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productModulePrices/${id}`);
          setEntityPrice([{}]);
          loadPricesEntity();
          addToast('Preço deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o preço, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a o preço ?',
      'delete',
    );
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idProductModuleState === undefined) {
          const response = await api.post('/productModules', entitySave);
          setIdProductModuleState(response.data.id);
          navigate(
            `/products/register/${idProduct}/module/${response.data.id}`,
            { replace: true }
          );
          addToast(
            'Módulo inserido com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          await api.put(`/productModules/${idProductModule}`, entitySave);
          navigate(-1);
          addToast('Módulo editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar o módulo, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de módulos de produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="75%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="25%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e);
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE PREÇOS</h1>
              <div>
                <button
                  disabled={idProductModuleState === undefined}
                  onClick={() => {
                    navigate(
                      `/products/register/${idProduct}/module/${idProductModuleState}/price`,
                    );
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="15%">Tipo</TableHeaderColumn>
              <TableHeaderColumn width="15%">Usu.Ativos</TableHeaderColumn>
              <TableHeaderColumn width="15%">Qtd.Docs</TableHeaderColumn>
              <TableHeaderColumn width="15%">Valor</TableHeaderColumn>
              <TableHeaderColumn width="15%">Qtd.Docs.Excedentes</TableHeaderColumn>
              <TableHeaderColumn width="15%">Valor Qtd.Docs.Execedentes</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityPrice[0] &&
                    entityPrice.map((e, index) => (
                      <tr key={index}>
                        <TableTd paddingTopBottom width="15%">
                          {e.produtoTipo}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {e.usuariosAtivos}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {e.quantidadeDocumentos}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {e.valor}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {e.quantidadeDocumentosExcedentes}
                        </TableTd>
                        <TableTd paddingTopBottom width="15%">
                          {e.valorQuantidadeDocumentosExcedentes}
                        </TableTd>
                        <TableTd paddingTopBottom center width="10%">
                          <button
                            type="button"
                            onClick={() => {
                              navigate(
                                `/products/register/${idProduct}/module/${idProductModuleState}/price/${e.id}`,
                              );
                            }}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              handlePriceDelete(e.id)
                            }}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#a531362f5234" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductModuleRegister;
