import React from 'react';

import { Container } from './styles';
import Tooltip from '../Tooltip';
import { FiSearch } from 'react-icons/fi';

interface FilterButtonProps {
  tooltipDescription: string;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ tooltipDescription, onClick, ...rest }) => (
  <Tooltip title={tooltipDescription}>
    <Container type="button" onClick={onClick} {...rest}>
      <FiSearch size={20} />
    </Container>
  </Tooltip>

);

export default FilterButton;
