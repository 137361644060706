import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../../../services/api';

import {
  getIndividualErrors,
  getErrors,
} from '../../../../../utils/validateForm';
import {
  changeForm,
  saveForm,
  loadForm,
} from '../../../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../../../utils/handleSelects';

import Confirmation from '../../../../../components/Confirmation';
import Select from '../../../../../components/Select';
import DatePicker from '../../../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
} from '../../../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  clienteProduto?: string;
  produtoModulo?: string;
  dataInativo?: Date | null;
}

interface ErrorsData {
  [key: string]: string;
}

const ClientProductModuleRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idClientProduct } = useParams<{ idClientProduct: string }>();
  const { idClientProductModule } = useParams<{
    idClientProductModule: string;
  }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    clienteProduto: idClientProduct,
    produtoModulo: '',
    dataInativo: null,
  });

  const [selectProductModules, setSelectProductModules] = useState([]);

  const [entityValidation] = useState({
    clienteProduto: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Produto'),
    produtoModulo: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Módulo'),
    dataInativo: Yup.date().nullable().label('Data de Inativação'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const responseClientProduct = await api.get(
          `/clientProducts/${idClientProduct}`,
        );

        const response = await api.get('/productModules', {
          params: {
            produto: responseClientProduct.data.produto,
          },
        });

        setSelectProductModules(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idClientProductModule !== undefined) {
        const response = await api.get(
          `/clientProductModules/${idClientProductModule}`,
        );
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['dataInativo'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idClientProductModule, idClientProduct]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idClientProductModule === undefined) {
          await api.post('/clientProductModules', entitySave);
        } else {
          await api.put(
            `/clientProductModules/${idClientProductModule}`,
            entitySave,
          );
        }
        addToast('Módulo do produto do cliente gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast(
          'Problemas ao gravar o módulo do produto do cliente, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Módulos de Produtos do Cliente</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="80%">
              <span>
                {`Módulo *
                ${errors.produtoModulo ? `(${errors.produtoModulo})` : ''}
                `}
              </span>
              <Select
                name="produtoModulo"
                height={40}
                maxMenuHeight={80}
                placeholder="Escolha o módulo"
                onBlur={(e: any) => handleValidation('produtoModulo')}
                onChange={(e: any) => {
                  handleChanges('produtoModulo', e.value);
                }}
                error={!!errors.produtoModulo}
                defaultValue={entity.produtoModulo}
                options={selectProductModules}
              />
            </FormField>
            <FormField size="20%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ClientProductModuleRegister;
