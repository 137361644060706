import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Switch from 'react-switch';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validFone,
  validCep,
} from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import {
  cpfMask,
  cnpjMask,
  cepMask,
  foneMask,
  moneyMask,
} from '../../../utils/handleMasks';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldWithSearch,
  FormFieldInput,
  FormFieldTextArea,
  FormTitle,
  FormButtons,
  TableTd,
  Button,
} from '../../../styles/registers';
import { getCEP } from '../../../utils/handleCEP';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  nome: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone1: string;
  telefone2: string;
  telefone3: string;
  email1: string;
  email2: string;
  email3: string;
  contatos: string;
  obs: string;
  tipo: string;
}
interface ErrorsData {
  [key: string]: string;
}

const ContactRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idContact } = useParams<{ idContact: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    nome: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone1: '',
    telefone2: '',
    telefone3: '',
    email1: '',
    email2: '',
    email3: '',
    contatos: '',
    obs: '',
    tipo: '',
  });

  const [idContactState, setIdContactState] = useState(idContact);

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectTipos, setSelectTipos] = useState([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      let response;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      try {
        response = await api.get('/contactTypes');
        setSelectTipos(changeNamesOfColumns(response.data, 'descricao', 'id'));
      } catch (err) {
        // manipulacao do erro
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idContactState !== undefined) {
        const response = await api.get(`/contacts/${idContactState}`);
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idContactState]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      nome: Yup.string().required('Preenchimento obrigatório').label('Nome'),
      logradouro: Yup.string(),
      numero: Yup.string(),
      complemento: Yup.string(),
      bairro: Yup.string(),
      cep: Yup.string()
        .nullable()
        .label('CEP')
        .test('testeCEPOK', 'CEP com formato inválido', function validate(
          value,
        ) {
          return validCep(value as string);
        }),
      cidade: Yup.string(),
      telefone1: Yup.string()
        .nullable()
        .label('Telefone 1')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone2: Yup.string()
        .nullable()
        .label('Telefone 2')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      telefone3: Yup.string()
        .nullable()
        .label('Telefone 3')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      email1: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 1'),
      email2: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 2'),
      email3: Yup.string()
        .email('E-mail com formato inválido')
        .label('E-mail 3'),
      contatos: Yup.string(),
      obs: Yup.string(),
      tipo: Yup.string().required('Preenchimento obrigatório').label('Tipo'),
    });
  }, []);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idContact === undefined) {
          await api.post('/contacts', entitySave);
        } else {
          await api.put(`/contacts/${idContact}`, entitySave);
        }
        addToast('Contato gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err) {
        addToast('Problemas ao gravar o contato, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSearchCEP(cep: string) {
    try {
      const data = await getCEP(cep);

      if (data.erro) {
        addToast('CEP não encontrado, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const entityModified: EntityData = entity;
      if (data.logradouro) {
        entityModified.logradouro = data.logradouro;
      }

      if (data.bairro) entityModified.bairro = data.bairro;

      if (data.localidade) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.localidade.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err) {
      addToast('Problemas ao buscar dados do CEP, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Contatos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`Nome *
                ${errors.nome ? `(${errors.nome})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.nome}
                id="nome"
                autoComplete="off"
                error={!!errors.nome}
                placeholder="Digite o nome"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="20%">
              <span>
                {`CEP
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'cep');
                  }}
                  value={entity.cep}
                  id="cep"
                  maxLength={9}
                  autoComplete="off"
                  error={!!errors.cep}
                  placeholder="Digite o CEP"
                />
                <button
                  onClick={() => handleSearchCEP(entity.cep)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="80%">
              <span>
                {`Logradouro
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCidades}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>CONTATO PRINCIPAL</FormTitle>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Telefone 1
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Telefone 2
                ${errors.telefone2 ? `(${errors.telefone2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="33.34%">
              <span>
                {`Telefone 3
                ${errors.telefone3 ? `(${errors.telefone3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone3}
                autoComplete="off"
                id="telefone3"
                maxLength={15}
                error={!!errors.telefone3}
                placeholder="Digite o telefone"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="33.33%">
              <span>
                {`Email 1
                ${errors.email1 ? `(${errors.email1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Email 2
                ${errors.email2 ? `(${errors.email2})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="33.33%">
              <span>
                {`Email 3
                ${errors.email3 ? `(${errors.email3})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email3}
                autoComplete="off"
                id="email3"
                error={!!errors.email3}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>OUTRAS INFORMAÇÕES</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`Tipo *
                ${errors.tipo ? `(${errors.tipo})` : ''}
                `}
              </span>
              <Select
                name="tipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o tipo"
                onBlur={(e: any) => handleValidation('tipo')}
                onChange={(e: any) => {
                  handleChanges('tipo', e.value);
                }}
                error={!!errors.tipo}
                defaultValue={entity.tipo}
                options={selectTipos}
              />
            </FormField>
            <FormField size="50%">
              <span>Contatos</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.contatos}
                id="contatos"
                autoComplete="off"
                error={!!errors.contatos}
                placeholder="Digite o(s) contato(s)"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>OBSERVAÇÕES</FormTitle>
          <FormLine height="130px">
            <FormField size="100%">
              <span>
                {`Observações
                ${errors.obs ? `(${errors.obs})` : ''}
                `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.obs}
                autoComplete="off"
                id="obs"
                error={!!errors.obs}
                placeholder="Digite as observações"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ContactRegister;
