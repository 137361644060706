import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  descricao: string;
  subGrupo: string;
  tipo: string;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const CashPlaceAccountRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idCashPlaceAccount } = useParams<{ idCashPlaceAccount: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    descricao: '',
    subGrupo: '',
    tipo: 'D',
  });

  const [selectSubGrupos, setSelectSubGrupos] = useState([]);

  const [selectDebitoCredito, setSelectDebitoCredito] = useState<SelectData[]>(
    [],
  );

  const [entityValidation] = useState({
    descricao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Descrição'),
    subGrupo: Yup.string()
      .required('Preenchimento obrigatório')
      .label('SubGrupo'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      try {
        const response = await api.get('/cashPlaceSubGroups');
        setSelectSubGrupos(
          changeNamesOfColumns(response.data, 'descricao', 'id'),
        );
      } catch (err) {
        // manipulacao do erro
      }

      setSelectDebitoCredito([
        {
          label: 'DÉBITO',
          value: 'D',
        },
        {
          label: 'CRÉDITO',
          value: 'C',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idCashPlaceAccount !== undefined) {
        const response = await api.get(
          `/cashPlaceAccounts/${idCashPlaceAccount}`,
        );
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idCashPlaceAccount]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        if (idCashPlaceAccount === undefined) {
          await api.post('/cashPlaceAccounts', entitySave);
        } else {
          await api.put(`/cashPlaceAccounts/${idCashPlaceAccount}`, entitySave);
        }
        addToast('Conta gravada com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err: any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar a conta, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Contas</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="40%">
              <span>
                {`
                Descrição *
                ${errors.descricao ? `(${errors.descricao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.descricao}
                id="descricao"
                autoComplete="off"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`SubGrupo *
                ${errors.subGrupo ? `(${errors.subGrupo})` : ''}
                `}
              </span>
              <Select
                name="subGrupo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o subGrupo"
                onBlur={(e: any) => handleValidation('subGrupo')}
                onChange={(e: any) => {
                  handleChanges('subGrupo', e.value);
                }}
                error={!!errors.subGrupo}
                defaultValue={entity.subGrupo}
                options={selectSubGrupos}
              />
            </FormField>
            <FormField size="20%">
              <span>Débito/Crédito</span>
              <Select
                name="tipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre débito e crédito"
                onChange={(e: any) => {
                  handleChanges('tipo', e.value);
                }}
                defaultValue={entity.tipo}
                options={selectDebitoCredito}
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default CashPlaceAccountRegister;
