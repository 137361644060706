import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/AuthContext';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';
import { foneMask, cepMask } from '../../../utils/handleMasks';
import { manipulateDates } from '../../../utils/handleLists';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  FormTable,
  FormFieldTextArea,
  Table,
  TableTd,
  Button,
  TableHeader,
  TableHeaderColumn,
} from '../../../styles/registers';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  empresa: string;
  solicitacao: string;
  logradouro: string;
  numero: string;
  bairro: string;
  cep: string;
  complemento: string;
  cidade: string;
  telefone1: string;
  telefone2: string;
  email1: string;
  email2: string;
  responsavel: string;
  dataAviso: Date | null;
  dataFechamento: Date | null;
  dataCancelamento: Date | null;
  motivoCancelamento: string;
  obs: string;
}

interface EntityStoriesData {
  id?: string;
  created_at?: Date;
  colaborador?: string;
  descricao?: string;
}

interface ErrorsData {
  [key: string]: string;
}

const LeadRegister: React.FC = () => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { idLead } = useParams<{ idLead: string }>();
  const { addToast } = useToasts();
  const [idLeadState, setIdTaskState] = useState(idLead);

  const [entity, setEntity] = useState<EntityData>({
    empresa: '',
    solicitacao: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cep: '',
    cidade: '',
    complemento: '',
    telefone1: '',
    telefone2: '',
    email1: '',
    email2: '',
    responsavel: '',
    dataAviso: null,
    dataFechamento: null,
    dataCancelamento: null,
    motivoCancelamento: '',
    obs: '',
  });

  const [entityStories, setEntityStories] = useState<EntityStoriesData[]>([{}]);

  const [entityValidation] = useState({
    empresa: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Empresa'),
    solicitacao: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Solicitação'),
    logradouro: Yup.string().nullable(),
    numero: Yup.string().nullable(),
    bairro: Yup.string().nullable(),
    cep: Yup.string().nullable(),
    complemento: Yup.string().nullable(),
    cidade: Yup.string().nullable(),
    telefone1: Yup.string()
      .required('Preenchimento obrigatório')
      .label('Telefone 1'),
    telefone2: Yup.string().nullable(),
    email1: Yup.string().nullable(),
    email2: Yup.string().nullable(),
    responsavel: Yup.string().nullable(),
    dataAviso: Yup.date().nullable(),
    dataFechamento: Yup.date().nullable(),
    dataCancelamento: Yup.date().nullable(),
    motivoCancelamento: Yup.string().nullable(),
    obs: Yup.string().nullable(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const [selectCities, setSelectCities] = useState([]);

  const loadStoriesEntity = useCallback(async () => {
    if (!idLeadState) return;

    try {
      const response = await api.get('/leadStories', {
        params: {
          interesse: idLeadState,
        },
      });
      const responseDisassembled = response.data.map((item: any) => {
        return {
          id: item.id,
          created_at: item.created_at,
          colaborador: item.colaboradores.nome,
          descricao: item.descricao,
        };
      });
      setEntityStories(responseDisassembled);
    } catch (error) {
    }
  }, [idLeadState]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const responseCities = await api.get('/cities');
        setSelectCities(
          changeNamesOfColumns(responseCities.data, 'nome', 'id'),
        );
      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idLeadState !== undefined) {
        const response = await api.get(`/leads/${idLeadState}`);
        const entityManipulated: any = loadForm(
          response.data,
          [],
          ['created_at', 'dataAviso', 'dataFechamento', 'dataCancelamento'],
          [],
        );

        delete entityManipulated.id;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    loadStoriesEntity();
  }, [idLeadState, loadStoriesEntity, user]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'fone') {
      newValue = foneMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);

    if (entity.dataCancelamento !== null && entity.motivoCancelamento === '') {
      addToast('Para cancelar um interesse, defina o motivo', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (validation.b) {
      try {
        const entitySave = saveForm(entity, []);
        delete entitySave.created_at;
        if (idLeadState === undefined) {
          const response = await api.post('/leads', entitySave);
          setIdTaskState(response.data.id);
          navigate(`/leads/register/${response.data.id}`, { replace: true });
          addToast(
            'Interesse inserido com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          await api.put(`/leads/${idLeadState}`, entitySave);
          navigate(-1);
          addToast('Interesse editado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar a tarefa, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handleStoryDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/leadStories/${id}`);
          setEntityStories([{}]);
          loadStoriesEntity();
          addToast('Histórico deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o histórico, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o tópico ?',
      'delete',
    );
  }

  function handleSecondaryEdit(page: string, id?: string) {
    navigate(`/leads/register/${idLead}/${page}/${id}`);
  }

  function handleSecondaryInsert(page: string) {
    navigate(`/leads/register/${idLeadState}/${page}`);
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Interesses</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Empresa *
                ${errors.empresa ? `(${errors.empresa})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.empresa}
                id="empresa"
                autoComplete="off"
                error={!!errors.empresa}
                placeholder="Digite a empresa"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="100%">
              <span>
                {`
                Solicitação *
                ${errors.solicitacao ? `(${errors.solicitacao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.solicitacao}
                id="solicitacao"
                autoComplete="off"
                error={!!errors.solicitacao}
                placeholder="Digite a solicitação"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>CONTATO</FormTitle>
          <FormLine>
            <FormField size="100%">
              <span>Responsável</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.responsavel}
                id="responsavel"
                autoComplete="off"
                error={!!errors.responsavel}
                placeholder="Digite a responsável"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="50%">
              <span>
                {`Telefone 1 *
                ${errors.telefone1 ? `(${errors.telefone1})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone1}
                autoComplete="off"
                id="telefone1"
                maxLength={15}
                error={!!errors.telefone1}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="50%">
              <span>Telefone 2</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone2}
                autoComplete="off"
                id="telefone2"
                maxLength={15}
                error={!!errors.telefone2}
                placeholder="Digite o telefone"
              />
            </FormField>
          </FormLine>

          <FormLine>
            <FormField size="50%">
              <span>Email 1</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email1}
                autoComplete="off"
                id="email1"
                error={!!errors.email1}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="50%">
              <span>Email 2</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email2}
                autoComplete="off"
                id="email2"
                error={!!errors.email2}
                placeholder="Digite o e-mail"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>ENDEREÇO</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>CEP</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'cep');
                }}
                value={entity.cep}
                id="cep"
                maxLength={9}
                autoComplete="off"
                error={!!errors.cep}
                placeholder="Digite o CEP"
              />
            </FormField>
            <FormField size="80%">
              <span>Logradouro</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>Número</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>Bairro</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>Complemento</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>Cidade</span>
              <Select
                name="cidade"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha a cidade"
                onBlur={(e: any) => handleValidation('cidade')}
                onChange={(e: any) => {
                  handleChanges('cidade', e.value);
                }}
                error={!!errors.cidade}
                defaultValue={entity.cidade}
                options={selectCities}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>PARÂMETROS</FormTitle>
          <FormLine>
            <FormField size="20%">
              <span>Data para aviso</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataAviso')}
                error={!!errors.dataAviso}
                onChange={(e: any) => {
                  handleChanges('dataAviso', e, 'date');
                }}
                placeholderText="Defina a data de aviso"
                selected={entity.dataAviso}
              />
            </FormField>
            <FormField size="20%">
              <span>Data de fechamento</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataFechamento')}
                error={!!errors.dataFechamento}
                onChange={(e: any) => {
                  handleChanges('dataFechamento', e, 'date');
                }}
                placeholderText="Defina a data de fechamento"
                selected={entity.dataFechamento}
              />
            </FormField>
            <FormField size="20%">
              <span>Data de cancelamento</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataCancelamento')}
                error={!!errors.dataCancelamento}
                onChange={(e: any) => {
                  handleChanges('dataCancelamento', e, 'date');
                }}
                placeholderText="Defina a data de cancelamento"
                selected={entity.dataCancelamento}
              />
            </FormField>
            <FormField size="40%">
              <span>Motivo de cancelamento</span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.motivoCancelamento}
                autoComplete="off"
                id="motivoCancelamento"
                error={!!errors.motivoCancelamento}
                placeholder="Digite o motivo do cancelamento"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTitle>OBSERVAÇÕES</FormTitle>
          <FormLine height="100px">
            <FormField size="100%">
              <span>Descrição detalhada da solicitação</span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.obs}
                autoComplete="off"
                id="obs"
                error={!!errors.obs}
                placeholder="Digite as observações"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTable height="400px">
            <div>
              <h1>LISTA DE HISTÓRICO</h1>
              <div>
                <button
                  disabled={idLeadState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('story');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>

            <TableHeader>
              <TableHeaderColumn width="15%">Data</TableHeaderColumn>
              <TableHeaderColumn width="65%">Descrição</TableHeaderColumn>
              <TableHeaderColumn width="10%">Colaborador</TableHeaderColumn>
            </TableHeader>

            <Table height="300px">
              <table>
                <tbody>
                  {'id' in entityStories[0] &&
                    entityStories.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="15%">
                          {e.created_at &&
                            manipulateDates(String(e.created_at))}
                        </TableTd>
                        <TableTd width="65%">{e.descricao}</TableTd>
                        <TableTd width="10%">{e.colaborador}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('story', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleStoryDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default LeadRegister;
