import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import Switch from 'react-switch';

import api from '../../../../services/api';

import { getIndividualErrors, getErrors } from '../../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../../utils/handleForms';

import Confirmation from '../../../../components/Confirmation';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormFieldTextArea,
  FormButtons,
  Button,
  Table,
  TableTd,
  FormTable,
  TableHeader,
  TableHeaderColumn,
} from '../../../../styles/registers';

interface EntityData {
  produto: string | undefined;
  versao: string;
  nota: string;
  producao: number;
}

interface EntityTaskData {
  id?: string;
  produtoVersao?: string;
  descricao?: string;
}

interface EntityPendencyData {
  id?: string;
  produtoVersao?: string;
  descricao?: string;
}

interface EntityFileData {
  id?: string;
  produtoVersao?: string;
  apelido?: string;
  nome?: string;
  diretorio?: string;
}

interface ErrorsData {
  [key: string]: string;
}

const ProductVersionRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idProduct } = useParams<{ idProduct: string }>();
  const { idProductVersion } = useParams<{ idProductVersion: string }>();
  const { addToast } = useToasts();

  const [idProductVersionState, setIdProductVersionState] = useState(
    idProductVersion,
  );
  const [entity, setEntity] = useState<EntityData>({
    produto: idProduct,
    versao: '',
    nota: '',
    producao: 0,
  });

  const [entityTask, setEntityTask] = useState<EntityTaskData[]>([{}]);
  const [entityPendency, setEntityPendency] = useState<EntityPendencyData[]>([
    {},
  ]);
  const [entityFile, setEntityFile] = useState<EntityFileData[]>([{}]);

  const [entityValidation] = useState({
    produto: Yup.string().required('Preenchimento obrigatório'),
    versao: Yup.string().required('Preenchimento obrigatório'),
    nota: Yup.string().required('Preenchimento obrigatório'),
    producao: Yup.number().nullable(),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const loadTasksEntity = useCallback(async () => {
    if (!idProductVersionState) return;

    try {
      const response = await api.get('/productVersionTasks', {
        params: {
          produtoVersao: idProductVersionState,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          produtoVersao: item.produtoVersao,
          descricao: item.descricao,
        };
      });
      setEntityTask(responseDisassembled);
    } catch (error) {
    }
  }, [idProductVersionState]);

  const loadPendenciesEntity = useCallback(async () => {
    if (!idProductVersionState) return;

    try {
      const response = await api.get('/productVersionPendencies', {
        params: {
          produtoVersao: idProductVersionState,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          produtoVersao: item.produtoVersao,
          descricao: item.descricao,
        };
      });
      setEntityPendency(responseDisassembled);
    } catch (error) {
    }
  }, [idProductVersionState]);

  const loadFilesEntity = useCallback(async () => {
    if (!idProductVersionState) return;

    try {
      const response = await api.get('/productVersionFiles', {
        params: {
          produtoVersao: idProductVersionState,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          produtoVersao: item.produtoVersao,
          apelido: item.apelido,
          nome: item.nome,
          diretorio: item.diretorio,
        };
      });
      setEntityFile(responseDisassembled);
    } catch (error) {
    }
  }, [idProductVersionState]);

  useEffect(() => {
    async function loadEntity() {
      if (idProductVersionState !== undefined) {
        const response = await api.get(
          `/productVersions/${idProductVersionState}`,
        );
        const entityManipulated: any = loadForm(response.data, [], [], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;
        delete entityManipulated.ordem;

        setEntity(entityManipulated);
      }
    }
    loadEntity();
    loadTasksEntity();
    loadFilesEntity();
    loadPendenciesEntity();
  }, [
    idProductVersionState,
    loadTasksEntity,
    loadPendenciesEntity,
    loadFilesEntity,
  ]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<boolean> {
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return validate;
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation) {
      try {
        const entitySave = saveForm(entity, []);
        if (idProductVersionState === undefined) {
          const response = await api.post('/productVersions', entitySave);
          setIdProductVersionState(response.data.id);
          navigate(
            `/products/register/${idProduct}/version/${response.data.id}`,
            { replace: true }
          );
          addToast(
            'Versão inserida com sucesso, clique novamente para fechar ou insira registros secundários',
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          await api.put(
            `/productVersions/${idProductVersionState}`,
            entitySave,
          );
          navigate(-1);
          addToast('Versão editada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        addToast('Problemas ao gravar a versão, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      addToast('Registros obrigatórios incompletos, verifique', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function handlePendencyDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productVersionPendencies/${id}`);
          setEntityPendency([{}]);
          loadPendenciesEntity();
          addToast('Pendência deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a pendência, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a pendência ?',
      'delete',
    );
  }

  function handleTaskDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productVersionTasks/${id}`);
          setEntityTask([{}]);
          loadTasksEntity();
          addToast('Tarefa deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a tarefa, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a tarefa ?',
      'delete',
    );
  }

  function handleFileDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/productVersionFiles/${id}`);
          setEntityFile([{}]);
          loadFilesEntity();
          addToast('Arquivo deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o arquivo, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o arquivo ?',
      'delete',
    );
  }

  function handleSecondaryEdit(page: string, id?: string) {
    navigate(
      `/products/register/${idProduct}/version/${idProductVersionState}/${page}/${id}`,
    );
  }

  function handleSecondaryInsert(page: string) {
    navigate(
      `/products/register/${idProduct}/version/${idProductVersionState}/${page}`,
    );
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de versões de produtos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="90%">
              <span>
                {`
                Versão *
                ${errors.versao ? `(${errors.versao})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.versao}
                id="versao"
                autoComplete="off"
                error={!!errors.versao}
                placeholder="Digite a versão"
              />
            </FormField>
            <FormField size="10%">
              <span>Em produção</span>
              <Switch
                checked={!!entity.producao}
                onChange={() => {
                  handleChanges('producao', entity.producao === 0 ? 1 : 0);
                }}
              />
            </FormField>
          </FormLine>
          <FormLine height="130px">
            <FormField size="100%">
              <span>
                {`
                Notas da versão *
                ${errors.nota ? `(${errors.nota})` : ''}
                `}
              </span>
              <FormFieldTextArea
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.nota}
                autoComplete="off"
                id="nota"
                error={!!errors.nota}
                placeholder="Digite as notas da versão"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE PENDÊNCIAS</h1>
              <div>
                <button
                  disabled={idProductVersionState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('pendency');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Descrição</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityPendency[0] &&
                    entityPendency.map((e, index) => (
                      <tr key={index}>
                        <TableTd paddingTopBottom width="90%">
                          {e.descricao}
                        </TableTd>
                        <TableTd paddingTopBottom center width="10%">
                          <button
                            type="button"
                            onClick={() => {
                              handleSecondaryEdit('pendency', e.id);
                            }}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handlePendencyDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE TAREFAS</h1>
              <div>
                <button
                  disabled={idProductVersionState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('task');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="90%">Descrição</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityTask[0] &&
                    entityTask.map((e, index) => (
                      <tr key={index}>
                        <TableTd paddingTopBottom width="90%">
                          {e.descricao}
                        </TableTd>
                        <TableTd paddingTopBottom center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('task', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTaskDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormBlock>
          <FormTable height="330px">
            <div>
              <h1>LISTA DE ARQUIVOS</h1>
              <div>
                <button
                  disabled={idProductVersionState === undefined}
                  onClick={() => {
                    handleSecondaryInsert('file');
                  }}
                  type="button"
                >
                  <FaPlus size={14} color="#faede8" />
                  Adicionar
                </button>
              </div>
            </div>
            <TableHeader>
              <TableHeaderColumn width="30%">Apelido</TableHeaderColumn>
              <TableHeaderColumn width="30%">Nome</TableHeaderColumn>
              <TableHeaderColumn width="30%">Diretório</TableHeaderColumn>
            </TableHeader>
            <Table height="230px">
              <table>
                <tbody>
                  {'id' in entityFile[0] &&
                    entityFile.map((e, index) => (
                      <tr key={index}>
                        <TableTd width="30%">{e.apelido}</TableTd>
                        <TableTd width="30%">{e.nome}</TableTd>
                        <TableTd width="30%">{e.diretorio}</TableTd>
                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleSecondaryEdit('file', e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleFileDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </FormTable>
        </FormBlock>

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ProductVersionRegister;
