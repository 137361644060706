import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  FaPlus,
  FaClipboardList,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';

import api from '../../services/api';

import { report } from '../../utils/printReport';
import { changeNamesOfColumns } from '../../utils/handleSelects';
import { orderBy as handleOrderBy } from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  Filters,
  FiltersLine,
  FiltersField,
  Tables,
  TableTd,
  TablesPagination,
} from '../../styles/lists';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  nome?: string;
  funcao?: string;
  cidade?: string;
}

const Collaborator: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'nome',
    desc: false,
  });

  const filters: any = getFilters('collaborators');

  const [nomeFiltro, setNomeFiltro] = useState(
    Object.keys(filters).length > 0 ? filters.nomeFiltro : '',
  );
  const [cidades, setCidades] = useState<string>(
    Object.keys(filters).length > 0 ? filters.cidades : '',
  );
  const [funcoes, setFuncoes] = useState<string>(
    Object.keys(filters).length > 0 ? filters.funcoes : '',
  );

  function saveFilters() {
    setFilters(
      {
        nomeFiltro,
        cidades,
        funcoes,
      },
      'collaborators',
    );
  }

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectFuncoes, setSelectFuncoes] = useState([]);

  const load = useCallback(async () => {
    try {
      const response = await api.get('/collaborators', {
        params: {
          nome: nomeFiltro,
          cidade: cidades,
          funcao: funcoes,
        },
      });
      const responseDisassembled = await response.data.map((item: any) => {
        return {
          id: item.id,
          nome: item.nome,
          cidade: item.cidades?.nome,
          funcao: item.funcoes?.descricao,
        };
      });
      const responseOrdered: any = await handleOrderBy(
        responseDisassembled,
        order,
      );
      setEntity(responseOrdered);
      setPages(Math.ceil(response.data.length / 10));
      setActualPage(Math.ceil(response.data.length / 10) > 0 ? 1 : 0);
    } catch (err) {
      setEntity([{}]);
      setPages(1);
      setActualPage(1);
    }
  }, [nomeFiltro, cidades, funcoes, order]);

  useEffect(() => {
    async function loadCities() {
      const response = await api.get('/cities');
      setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCities();

    async function loadCollaboratorFunctions() {
      const response = await api.get('/collaboratorFunctions');
      setSelectFuncoes(changeNamesOfColumns(response.data, 'descricao', 'id'));
    }
    loadCollaboratorFunctions();

    load();
  }, [load]);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 10;
    return actualPage * 10;
  }

  function handlePrint() {
    const header = '<th>NOME</th><th>CIDADE</th><th>FUNÇÃO</th>';
    let content = '';
    entity.map(
      // eslint-disable-next-line no-return-assign
      contact =>
      (content =
        `${content}<tr key=${contact.id}> ` +
        `<td>${contact.nome}</td> ` +
        `<td>${contact.cidade}</td> ` +
        `<td>${contact.funcao}</td> ` +
        `</tr>`),
    );
    report('RELAÇÃO DE COLABORADORES', header, content);
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/collaborators/${id}`);
          load();
          addToast('Colaborador deletado com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar o colaborador, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir o colaborador ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/collaborators/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/collaborators/register');
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Colaboradores</h1>
      </Title>
      <Buttons>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#ffffff" />
          Adicionar
        </Button>
        <Button visual="secondary" onClick={handlePrint}>
          <FaClipboardList size={18} color="#1362f5" />
          Imprimir
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersField size="40%">
            <input
              value={nomeFiltro}
              onChange={e => setNomeFiltro((e.target.value as any) || '')}
              id="nomeFiltro"
              placeholder="Filtre pelo nome"
            />
          </FiltersField>
          <FiltersField size="30%">
            <Select
              name="cidades"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelas cidades"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setCidades(e.value);
                } else {
                  setCidades('');
                }
              }}
              defaultValue={cidades}
              options={selectCidades}
            />
          </FiltersField>
          <FiltersField size="30%">
            <Select
              name="tipos"
              height={40}
              clear
              maxMenuHeight={150}
              placeholder="Filtre pela função"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setFuncoes(e.value);
                } else {
                  setFuncoes('');
                }
              }}
              defaultValue={funcoes}
              options={selectFuncoes}
            />
          </FiltersField>
        </FiltersLine>
      </Filters>
      <Tables>
        <table>
          <thead>
            <tr>
              <th>
                <div>
                  Nome
                  <button
                    onClick={() => {
                      orderBy('nome');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('nome', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('nome', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Cidade
                  <button
                    onClick={() => {
                      orderBy('cidade');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('cidade', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('cidade', true)}
                    />
                  </button>
                </div>
              </th>
              <th>
                <div>
                  Tipo
                  <button
                    onClick={() => {
                      orderBy('funcao');
                    }}
                    type="button"
                  >
                    <FaChevronUp
                      size={16}
                      color={orderByColor('funcao', false)}
                    />
                    <FaChevronDown
                      size={16}
                      color={orderByColor('funcao', true)}
                    />
                  </button>
                </div>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {'id' in entity[0] &&
              entity
                .slice(handlePagination(1), handlePagination(2))
                .map((e, index) => (
                  <tr key={index}>
                    <TableTd width="40%">{e.nome}</TableTd>
                    <TableTd width="30%">{e.cidade}</TableTd>
                    <TableTd width="20%">{e.funcao}</TableTd>
                    <TableTd center width="10%">
                      <button type="button" onClick={() => handleEdit(e.id)}>
                        <FiEdit size={20} color="#Fafafa" />
                      </button>
                      <button type="button" onClick={() => handleDelete(e.id)}>
                        <FiTrash size={20} color="#d13337" />
                      </button>
                    </TableTd>
                  </tr>
                ))}
          </tbody>
        </table>
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Collaborator;
