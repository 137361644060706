import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import {
  FaPlus,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaRegIdBadge,
  FaRandom,
} from 'react-icons/fa';
import { FiAlertCircle, FiEdit, FiTrash } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import ClipLoader from 'react-spinners/ClipLoader';

import Select from '../../components/Select';
import Confirmation from '../../components/Confirmation';
import DatePicker from '../../components/DatePicker';
import Progress from '../../components/Progress';

import api from '../../services/api';

import { useAuth } from '../../hooks/AuthContext';

import { changeNamesOfColumns } from '../../utils/handleSelects';
import {
  manipulateDates,
  orderBy as handleOrderBy,
} from '../../utils/handleLists';
import { setFilters, getFilters } from '../../utils/handleFilters';

import {
  Container,
  Title,
  Buttons,
  Button,
  ButtonLink,
  Filters,
  FiltersField,
  Legend,
  Legends,
  Tables,
  TableTd,
  TableTdCard,
  TablesPagination,
  FormLoading,
} from '../../styles/lists';

import { CustomFiltersLine } from './styles';

interface OrderParams {
  field: string;
  desc: boolean;
}

interface EntityParams {
  id?: string;
  created_at?: string;
  cliente?: string;
  descricao?: string;
  status?: string;
  prioritaria?: number;
  participantes?: string[];
  progresso?: number;
}

const Task: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [waitLoad, setWaitLoad] = useState(true);

  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityParams[]>([{}]);
  const [pages, setPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const [order, setOrder] = useState<OrderParams>({
    field: 'created_at',
    desc: true,
  });

  const filters: any = getFilters('tasks');

  const [cliente, setCliente] = useState(
    Object.keys(filters).length > 0 ? filters.cliente : '',
  );
  const [colaborador, setColaborador] = useState<string>(
    Object.keys(filters).length > 0 ? filters.colaborador : '',
  );
  const [descricao, setDescricao] = useState(
    Object.keys(filters).length > 0 ? filters.descricao : '',
  );
  const [descricaoTopico, setDescricaoTopico] = useState(
    Object.keys(filters).length > 0 ? filters.descricaoTopico : '',
  );
  const [dataInicial, setDataIncial] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataInicial !== null
      ? parseISO(filters.dataInicial)
      : null,
  );
  const [dataFinal, setDataFinal] = useState<Date | null>(
    Object.keys(filters).length > 0 && filters.dataFinal !== null
      ? parseISO(filters.dataFinal)
      : null,
  );
  const [status, setStatus] = useState(
    Object.keys(filters).length > 0
      ? filters.status
      : [
        { label: 'AGUARDANDO', value: 'AGUARDANDO' },
        { label: 'PENDENTE', value: 'PENDENTE' },
        { label: 'EM ANDAMENTO', value: 'EM ANDAMENTO' },
        { label: 'CONCLUÍDA', value: 'CONCLUÍDA' },
      ],
  );

  const [selectColaboradores, setSelectColaboradores] = useState([]);
  const [selectStatus, setSelectStatus] = useState([
    {
      label: 'CANCELADA',
      value: 'CANCELADA',
    },
    {
      label: 'AGUARDANDO',
      value: 'AGUARDANDO',
    },
    {
      label: 'PENDENTE',
      value: 'PENDENTE',
    },
    {
      label: 'EM ANDAMENTO',
      value: 'EM ANDAMENTO',
    },
    {
      label: 'CONCLUÍDA',
      value: 'CONCLUÍDA',
    },
    {
      label: 'ATUALIZADA',
      value: 'ATUALIZADA',
    },
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      if (!waitLoad) {
        try {
          const statusManipulated: string[] = [];
          const statusLoop: any[] = status || [];
          statusLoop.map(item => {
            statusManipulated.push(String(item.value));
            return true;
          });

          const response = await api.get('/tasks', {
            params: {
              descricao,
              tarefaTopicoTitulo: descricaoTopico,
              tarefaTopicoColaborador: colaborador,
              clienteNome: cliente,
              dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
              dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
              status: statusManipulated,
            },
          });
          const responseDisassembled = await response.data.map((item: any) => {
            return {
              id: item.id,
              created_at: item.created_at,
              cliente: item.clientes?.fantasia,
              descricao: item.descricao,
              status: item.status,
              prioritaria: item.prioritaria,
              participantes: item.participantes,
              progresso: item.progresso,
            };
          });
          const responseOrdered: any = await handleOrderBy(
            responseDisassembled,
            order,
          );
          setEntity(responseOrdered);
          setPages(Math.ceil(response.data.length / 20));
          setActualPage(Math.ceil(response.data.length / 20) > 0 ? 1 : 0);
          setLoading(false);
        } catch (err) {
          setEntity([{}]);
          setPages(1);
          setActualPage(1);
          setLoading(false);
        }
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [
    cliente,
    colaborador,
    descricao,
    descricaoTopico,
    dataInicial,
    dataFinal,
    status,
    order,
    waitLoad,
  ]);

  function saveFilters() {
    setFilters(
      {
        cliente,
        descricao,
        descricaoTopico,
        colaborador,
        dataInicial,
        dataFinal,
        status,
      },
      'tasks',
    );
  }

  useEffect(() => {
    async function loadCollaborator() {
      setWaitLoad(true);
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        if (user.nivel === 3) {
          setColaborador(response.data[0].id);
        }
      } catch (err) {
        //
      }
      setWaitLoad(false);
    }
    loadCollaborator();
  }, [user.id, user.nivel]);

  useEffect(() => {
    async function loadCollaborators() {
      const response = await api.get('/collaborators', {
        params: {
          status: 'ativo'
        }
      });
      setSelectColaboradores(changeNamesOfColumns(response.data, 'nome', 'id'));
    }
    loadCollaborators();
  }, []);

  async function orderBy(field: string) {
    const handleOrder: OrderParams =
      order.field === field
        ? { field, desc: !order.desc }
        : { field, desc: false };
    const result: any = await handleOrderBy(entity, handleOrder);
    setOrder(handleOrder);
    setEntity(result);
  }

  function orderByColor(field: string, desc: boolean) {
    return order.field === field && order.desc === desc ? '#1362f5' : '#dedcdc';
  }

  function handlePagination(p: number) {
    if (p === 1) return (actualPage - 1) * 20;
    return actualPage * 20;
  }

  function handleDelete(id?: string) {
    Confirmation(
      async () => {
        try {
          await api.delete(`/tasks/${id}`);
          // load();
          setCliente('1'); // para filtrar
          setCliente('');
          addToast('Tarefa deletada com sucesso', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Não foi possível deletar a tarefa, tente novamente', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      'Tem certeza que deseja excluir a tarefa ?',
      'delete',
    );
  }

  function handleEdit(id?: string) {
    saveFilters();
    navigate(`/tasks/register/${id}`);
  }

  function handleInsert() {
    saveFilters();
    navigate('/tasks/register');
  }

  function handlePanel() {
    navigate('/tasks/panel');
  }

  function handleLineColor(statusLine: string) {
    switch (statusLine) {
      case 'CANCELADA':
        return '#784a2a';
      case 'AGUARDANDO':
        return '#f0b351';
      case 'PENDENTE':
        return '#888888';
      case 'EM ANDAMENTO':
        return '#6aa3a3';
      case 'CONCLUÍDA':
        return '#5ce69e';
      case 'ATUALIZADA':
        return '#3979ed';
      default:
        return '#888888';
    }
  }

  return (
    <Container>
      <Title>
        <h1>Listagem de Tarefas</h1>
      </Title>
      <Buttons spaceBetween>
        <Button onClick={handleInsert}>
          <FaPlus size={18} color="#faede8" />
          Adicionar
        </Button>
        <ButtonLink onClick={handlePanel}>
          <FaRandom size={18} color="#faede8" />
          Trocar para painel
        </ButtonLink>
      </Buttons>
      <Filters>
        <CustomFiltersLine first>
          <FiltersField size="25%">
            <input
              value={cliente}
              onChange={e => setCliente((e.target.value as any) || '')}
              id="cliente"
              placeholder="Filtre pelo cliente"
            />
          </FiltersField>
          <FiltersField size="25%">
            <input
              value={descricao}
              onChange={e => setDescricao((e.target.value as any) || '')}
              id="descricao"
              placeholder="Filtre pela descrição da tarefa"
            />
          </FiltersField>
          <FiltersField size="25%">
            <input
              value={descricaoTopico}
              onChange={e => setDescricaoTopico((e.target.value as any) || '')}
              id="descricaoTopico"
              placeholder="Filtre pela descrição dos tópicos"
            />
          </FiltersField>
          <FiltersField size="25%">
            <Select
              name="colaborador"
              height={40}
              clear
              maxMenuHeight={300}
              placeholder="Filtre pelo colaborador"
              onChange={(e: any) => {
                // eslint-disable-next-line no-unused-expressions
                if (e !== null) {
                  setColaborador(e.value);
                } else {
                  setColaborador('');
                }
              }}
              defaultValue={colaborador}
              options={selectColaboradores}
            />
          </FiltersField>
        </CustomFiltersLine>
        <CustomFiltersLine second>
          <FiltersField size="25%">
            <DatePicker
              error={false}
              onChange={(e: any) => {
                setDataIncial(e);
              }}
              placeholderText="Data inicial"
              selected={dataInicial}
            />
          </FiltersField>
          <FiltersField size="25%">
            <DatePicker
              error={false}
              onChange={(e: any) => {
                setDataFinal(e);
              }}
              placeholderText="Data final"
              selected={dataFinal}
            />
          </FiltersField>
          <FiltersField size="50%">
            <Select
              name="status"
              height={40}
              clear
              multiple
              maxMenuHeight={300}
              placeholder="Filtre pelo status"
              onChange={(e: any) => {
                setStatus(e);
              }}
              value={status}
              options={selectStatus}
            />
          </FiltersField>
        </CustomFiltersLine>
      </Filters>
      <Legends>
        <Legend backgroundColor="#2e2c2c" fontColor="#888888">
          Prioritária:
          <FiAlertCircle
            style={{ marginLeft: '7px', marginRight: '5px' }}
            size={18}
            color="#d12637"
          />
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#784a2a">
          Cancelada
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#f0b351">
          Aguardando
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#888888">
          Pendente
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#6aa3a3">
          Em andamento
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#5ce69e">
          Concluída
        </Legend>
        <Legend backgroundColor="#2e2c2c" fontColor="#3979ed">
          Atualizada
        </Legend>
      </Legends>
      <Tables withLegend>
        {loading ? (
          <FormLoading>
            <ClipLoader size={30} color="#FFFFFF" loading={loading} />
          </FormLoading>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th> </th>
                  <th>
                    <div>
                      Data
                      <button
                        onClick={() => {
                          orderBy('created_at');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('created_at', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('created_at', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Cliente
                      <button
                        onClick={() => {
                          orderBy('cliente');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('cliente', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('cliente', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>
                      Descrição
                      <button
                        onClick={() => {
                          orderBy('descricao');
                        }}
                        type="button"
                      >
                        <FaChevronUp
                          size={16}
                          color={orderByColor('descricao', false)}
                        />
                        <FaChevronDown
                          size={16}
                          color={orderByColor('descricao', true)}
                        />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div>Participantes</div>
                  </th>
                  <th>
                    <div>Progresso</div>
                  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {'id' in entity[0] &&
                  entity
                    .slice(handlePagination(1), handlePagination(2))
                    .map((e, index) => (
                      <tr key={index}>
                        <TableTd center width="4%">
                          {e.prioritaria === 1 ? (
                            <FiAlertCircle size={18} color="#d12637" />
                          ) : (
                            ''
                          )}
                        </TableTd>
                        <TableTd
                          color={handleLineColor(String(e.status))}
                          width="12%"
                        >
                          {e.created_at && manipulateDates(e.created_at)}
                        </TableTd>
                        <TableTd
                          color={handleLineColor(String(e.status))}
                          width="27%"
                        >
                          {e.cliente}
                        </TableTd>
                        <TableTd
                          color={handleLineColor(String(e.status))}
                          width="27%"
                        >
                          {e.descricao}
                        </TableTd>
                        <TableTd
                          color={handleLineColor(String(e.status))}
                          width="14%"
                        >
                          {e.participantes?.map(item => {
                            return (
                              <TableTdCard key={item}>
                                <FaRegIdBadge size={15} color="#888888" />
                                {item}
                              </TableTdCard>
                            );
                          })}
                        </TableTd>

                        <TableTd center width="6%">
                          <Progress size={e.progresso || 0} height="12" />
                          <div style={{ marginTop: '5px' }}>
                            {`${
                              // eslint-disable-next-line no-nested-ternary
                              e.progresso !== undefined
                                ? e.progresso > 0
                                  ? e.progresso
                                  : '0.00 '
                                : '0.00 '
                              }%`}
                          </div>
                        </TableTd>

                        <TableTd center width="10%">
                          <button
                            type="button"
                            onClick={() => handleEdit(e.id)}
                          >
                            <FiEdit size={20} color="#Fafafa" />
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDelete(e.id)}
                          >
                            <FiTrash size={20} color="#d13337" />
                          </button>
                        </TableTd>
                      </tr>
                    ))}
              </tbody>
            </table>
          </>
        )}
        <TablesPagination>
          <span>
            {`${'id' in entity[0] ? entity.length : 0
              } registro(s) encontrado(s)`}
          </span>
          <div>
            <button
              type="button"
              onClick={() => actualPage > 1 && setActualPage(actualPage - 1)}
            >
              <FaArrowLeft
                size={30}
                color={actualPage <= 1 ? '#585858' : '#1362f5'}
              />
            </button>
            <strong>{`${actualPage}/${pages}`}</strong>
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                actualPage !== pages && setActualPage(actualPage + 1);
              }}
            >
              <FaArrowRight
                size={30}
                color={pages === actualPage ? '#585858' : '#1362f5'}
              />
            </button>
          </div>
        </TablesPagination>
      </Tables>
    </Container>
  );
};

export default Task;
