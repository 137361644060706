import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FaReply, FaSearch } from 'react-icons/fa';
import Switch from 'react-switch';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';

import { onlyNumbers } from '../../../utils/general';
import api from '../../../services/api';

import {
  getIndividualErrors,
  getErrors,
  validCpf,
  validCnpj,
  validFone,
} from '../../../utils/validateForm';
import { changeForm, loadForm } from '../../../utils/handleForms';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import {
  cpfMask,
  cnpjMask,
  cepMask,
  foneMask,
  moneyMask,
} from '../../../utils/handleMasks';

import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';

import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormFieldWithSearch,
} from '../../../styles/registers';
import { getCEP } from '../../../utils/handleCEP';
import apiEasyCloudGerencial from '../../../services/apiEasyCloudGerencial';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  razaoSocial: string;
  fantasia: string;
  dataInativo: Date | null;
  cpfCnpj: string;
  rgIe: string;
  im: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  telefone: string;
  email: string;
  nfe: number | null;
  nfce: number | null;
  nfse: number | null;
  boleto: number | null;
  codigo: number | null;
  consultaNotaDestinada: number | null;
}

interface ErrorsData {
  [key: string]: string;
}

interface SelectData {
  label: string;
  value: string;
}

const ManagementCompanyRegister: React.FC = () => {
  const navigate = useNavigate();
  const { idCompany } = useParams<{ idCompany: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    razaoSocial: '',
    fantasia: '',
    dataInativo: null,
    cpfCnpj: '',
    rgIe: '',
    im: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    telefone: '',
    email: '',
    nfe: 0,
    nfce: 0,
    nfse: 0,
    boleto: 0,
    codigo: null,
    consultaNotaDestinada: 0,
  });
  const [entityCreatedAt, setEntityCreatedAt] = useState(
    format(new Date(), 'dd/MM/yyyy'),
  );
  const [fisicaJuridica, setFisicaJuridica] = useState('1');
  const [idCompanyState, setIdCompanyState] = useState(idCompany);

  const [selectCidades, setSelectCidades] = useState([]);
  const [selectFisicaJuridica, setSelectFisicaJuridica] = useState<
    SelectData[]
  >([]);

  const [entityValidation, setEntityValidation] = useState({});
  const [errors, setErrors] = useState<ErrorsData>({});

  useEffect(() => {
    async function loadSelects() {
      let response;

      try {
        response = await api.get('/cities');
        setSelectCidades(changeNamesOfColumns(response.data, 'nome', 'id'));
      } catch (err) {
        // manipulacao do erro
      }

      setSelectFisicaJuridica([
        {
          label: 'FÍSICA',
          value: '0',
        },
        {
          label: 'JURÍDICA',
          value: '1',
        },
      ]);
    }
    loadSelects();

    async function loadEntity() {
      if (idCompanyState !== undefined) {
        const response = await apiEasyCloudGerencial.get(`/companies`, {
          auth: {
            username: String(process.env.REACT_APP_AUTH_USER_LOGIN_EASYCLOUD),
            password: String(
              process.env.REACT_APP_AUTH_USER_PASSWORD_EASYCLOUD,
            ),
          },
          params: {
            id: idCompanyState,
          },
        });

        const entityManipulated: any = loadForm(
          {
            ...response.data[0],
            cidade: response.data[0].cidades.nome,
          },
          [''],
          ['dataInativo'],
          ['nfe', 'nfce', 'nfse', 'boleto', 'consultaNotaDestinada'],
        );

        delete entityManipulated.id;

        setEntityCreatedAt(
          `${String(entityManipulated.created_at).substring(8, 10)}/${String(
            entityManipulated.created_at,
          ).substring(5, 7)}/${String(entityManipulated.created_at).substring(
            0,
            4,
          )}`,
        );

        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        setEntity(entityManipulated);

        const { cpfCnpj } = response.data[0];
        if (cpfCnpj.length === 18) {
          setFisicaJuridica('1');
        } else {
          setFisicaJuridica('0');
        }
      }
    }
    loadEntity();
  }, [idCompanyState]);

  useEffect(() => {
    setErrors({});
    setEntityValidation({
      razaoSocial: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'Razão social' : 'Nome'}`),
      fantasia: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'Fantasia' : 'Apelido'}`),
      dataInativo: Yup.date().nullable().label('Data Inativo'),
      cpfCnpj: Yup.string()
        .required('Preenchimento obrigatório')
        .label(`${fisicaJuridica === '1' ? 'CNPJ' : 'CPF'}`)
        .test(
          'testCpfCnpj',
          fisicaJuridica === '1' ? 'CNPJ Inválido' : 'CPF Inválido',
          async function validate(value) {
            const result =
              fisicaJuridica === '1'
                ? await validCnpj(value as string)
                : await validCpf(value as string);
            return result;
          },
        ),
      rgIe: Yup.string()
        .required('Preenchimento obrigatório')
        .label(
          `${fisicaJuridica === '1' ? 'Inscrição Estadual' : 'Registro Geral'}`,
        ),
      im: Yup.string(),
      logradouro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Logradouro'),
      numero: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Número'),
      complemento: Yup.string(),
      bairro: Yup.string()
        .required('Preenchimento obrigatório')
        .label('bairro'),
      cep: Yup.string()
        .required('Preenchimento obrigatório')
        .label('CEP')
        .length(9)
        .matches(/^\d{5}-\d{3}$/, 'CEP com formato inválido'),
      cidade: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Cidade'),
      telefone: Yup.string()
        .required('Preenchimento obrigatório')
        .label('Telefone')
        .test('testFoneOk', 'Telefone com formato inválido', function validate(
          value,
        ) {
          return validFone(value as string);
        }),
      email: Yup.string()
        .required('Preenchimento obrigatório')
        .email('E-mail com formato inválido')
        .label('E-mail'),
      nfe: Yup.number(),
      nfce: Yup.number(),
      nfse: Yup.number(),
      boleto: Yup.number(),
      codigo: Yup.number().nullable(),
      consultaNotaDestinada: Yup.number(),
    });
  }, [fisicaJuridica]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  async function handleChanges(id: string, value: any, type?: string) {
    let newValue;
    if (type === 'cpf') {
      newValue = cpfMask(value);
    } else if (type === 'cnpj') {
      newValue = cnpjMask(value);
    } else if (type === 'cep') {
      newValue = cepMask(value);
    } else if (type === 'fone') {
      newValue = foneMask(value);
    } else if (type === 'integer') {
      newValue = value === '' ? null : value;
    } else if (type === 'money') {
      newValue = moneyMask(value);
    } else {
      newValue = value;
    }
    const newEntity = changeForm(entity, id, newValue);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSearchCEP(cep: string) {
    try {
      const data = await getCEP(cep);

      if (data.erro) {
        addToast('CEP não encontrado, tente novamente', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const entityModified: EntityData = entity;
      if (data.logradouro) {
        entityModified.logradouro = data.logradouro;
      }

      if (data.bairro) entityModified.bairro = data.bairro;

      if (data.localidade) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.localidade.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err) {
      addToast('Problemas ao buscar dados do CEP, tente novamente', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async function handleSearchCNPJ(cnpj: string) {
    try {
      const cnpjFormated = await onlyNumbers(cnpj);
      const response = await api.get(`/receitaWS/${cnpjFormated}`);

      const { data } = response;
      const entityModified: EntityData = entity;

      entityModified.razaoSocial = data.nome;
      entityModified.fantasia = data.fantasia;
      entityModified.telefone = foneMask(data.telefone);
      entityModified.email = data.emai;
      entityModified.bairro = data.bairro;
      entityModified.cep = data.cep;
      entityModified.numero = data.numero;
      entityModified.logradouro = data.logradouro;

      if (data.municipio) {
        const cityReponse = await api.get(`cities/`, {
          params: {
            nomeCompleto: data.municipio.toUpperCase(),
          },
        });
        entityModified.cidade = cityReponse.data[0].id;
      }

      setEntity(entityModified);
      document.getElementById('bairro')?.focus();
      document.getElementById('logradouro')?.focus();
    } catch (err: any) {
      let error: string;
      if (err.response.data.message.message) {
        error = err.response.data.message.message;
      } else if (err.response.data.message) {
        error = err.response.data.message;
      } else {
        error = 'Problemas ao buscar dados do CNPJ, tente novamente';
      }
      addToast(error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Empresas</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>
          <FormLine>
            <FormField size="50%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'Razão social *' : 'Nome *'}
                ${errors.razaoSocial ? `(${errors.razaoSocial})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.razaoSocial}
                id="razaoSocial"
                autoComplete="off"
                error={!!errors.razaoSocial}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a razão social'
                    : 'Digite o nome'
                }
              />
            </FormField>
            <FormField size="33%">
              <span>
                {`
                 ${fisicaJuridica === '1' ? 'Nome fantasia *' : 'Apelido *'}
                ${errors.fantasia ? `(${errors.fantasia})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.fantasia}
                autoComplete="off"
                id="fantasia"
                error={!!errors.fantasia}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite o nome fantasia'
                    : 'Digite o apelido'
                }
              />
            </FormField>
            <FormField size="6%">
              <span>Código</span>
              <FormFieldInput
                value={entity.codigo ? entity.codigo : ''}
                id="codigo"
                disabled
              />
            </FormField>
            <FormField size="11%">
              <span>Inclusão</span>
              <FormFieldInput
                value={entityCreatedAt}
                id="dataInclusao"
                disabled
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="15%">
              <span>Física/Jurídica</span>
              <Select
                name="fisicajuridica"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha entre física e jurídica"
                onChange={(e: any) => {
                  setFisicaJuridica(e.value);
                  handleChanges(
                    'cpfCnpj',
                    entity.cpfCnpj,
                    e.value === '1' ? 'cnpj' : 'cpf',
                  );
                }}
                defaultValue={fisicaJuridica}
                options={selectFisicaJuridica}
              />
            </FormField>
            <FormFieldWithSearch size="35%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'CNPJ *' : 'CPF *'}
                ${errors.cpfCnpj ? `(${errors.cpfCnpj})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(
                      e.target.id,
                      e.target.value,
                      fisicaJuridica === '1' ? 'cnpj' : 'cpf',
                    );
                  }}
                  maxLength={fisicaJuridica === '1' ? 18 : 14}
                  value={entity.cpfCnpj}
                  autoComplete="off"
                  id="cpfCnpj"
                  placeholder={
                    fisicaJuridica === '1' ? 'Defina o CNPJ' : 'Defina o CPF'
                  }
                  error={!!errors.cpfCnpj}
                />
                <button
                  onClick={() => handleSearchCNPJ(entity.cpfCnpj)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="33%">
              <span>
                {`
                ${fisicaJuridica === '1' ? 'I.E *' : 'I.E/RG *'}
                ${errors.rgIe ? `(${errors.rgIe})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.rgIe}
                id="rgIe"
                autoComplete="off"
                error={!!errors.rgIe}
                placeholder={
                  fisicaJuridica === '1'
                    ? 'Digite a inscrição estadual'
                    : 'Digite o RG ou a inscrição estadual'
                }
              />
            </FormField>
            <FormField size="17%">
              <span>
                {`I.M
                ${errors.im ? `(${errors.im})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.im}
                id="im"
                autoComplete="off"
                error={!!errors.im}
                placeholder="Digite a inscrição municipal"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>ENDEREÇOS</FormTitle>
          <FormLine>
            <FormFieldWithSearch size="20%">
              <span>
                {`CEP *
                ${errors.cep ? `(${errors.cep})` : ''}
                `}
              </span>
              <div>
                <FormFieldInput
                  onBlur={e => handleValidation(e.target.id)}
                  onChange={e => {
                    handleChanges(e.target.id, e.target.value, 'cep');
                  }}
                  value={entity.cep}
                  id="cep"
                  maxLength={9}
                  autoComplete="off"
                  error={!!errors.cep}
                  placeholder="Digite o CEP"
                />
                <button
                  onClick={() => handleSearchCEP(entity.cep)}
                  type="button"
                >
                  <FaSearch size={20} color="#faede8" />
                </button>
              </div>
            </FormFieldWithSearch>
            <FormField size="80%">
              <span>
                {`Logradouro *
                ${errors.logradouro ? `(${errors.logradouro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.logradouro}
                autoComplete="off"
                id="logradouro"
                error={!!errors.logradouro}
                placeholder="Digite o logradouro"
              />
            </FormField>
          </FormLine>
          <FormLine>
            <FormField size="20%">
              <span>
                {`Número *
                ${errors.numero ? `(${errors.numero})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.numero}
                autoComplete="off"
                id="numero"
                error={!!errors.numero}
                placeholder="Digite o número"
              />
            </FormField>
            <FormField size="25%">
              <span>
                {`Bairro *
                ${errors.bairro ? `(${errors.bairro})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.bairro}
                autoComplete="off"
                id="bairro"
                error={!!errors.bairro}
                placeholder="Digite o bairro"
              />
            </FormField>
            <FormField size="15%">
              <span>
                {`Complemento
                ${errors.complemento ? `(${errors.complemento})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.complemento}
                autoComplete="off"
                id="complemento"
                error={!!errors.complemento}
                placeholder="Digite o complemento"
              />
            </FormField>
            <FormField size="40%">
              <span>
                {`Cidade *
                ${errors.cidade ? `(${errors.cidade})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => handleChanges(e.target.id, e.target.value)}
                value={entity.cidade}
                autoComplete="off"
                id="cidade"
                error={!!errors.cidade}
                placeholder="Digite a cidade"
              />
            </FormField>
          </FormLine>
        </FormBlock>
        <FormBlock>
          <FormTitle>Outras Informações</FormTitle>
          <FormLine>
            <FormField size="25%">
              <span>
                {`Telefone *
                ${errors.telefone ? `(${errors.telefone})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value, 'fone');
                }}
                value={entity.telefone}
                autoComplete="off"
                id="telefone"
                maxLength={15}
                error={!!errors.telefone}
                placeholder="Digite o telefone"
              />
            </FormField>
            <FormField size="30%">
              <span>
                {`Email *
                ${errors.email ? `(${errors.email})` : ''}
                `}
              </span>
              <FormFieldInput
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.email}
                autoComplete="off"
                id="email"
                error={!!errors.email}
                placeholder="Digite o e-mail"
              />
            </FormField>
            <FormField size="10%">
              <span>Data de inativação</span>
              <DatePicker
                onBlur={(e: any) => handleValidation('dataInativo')}
                error={!!errors.dataInativo}
                onChange={(e: any) => {
                  handleChanges('dataInativo', e, 'date');
                }}
                placeholderText="Defina a data de inativação"
                selected={entity.dataInativo}
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`Emite NF-e
                ${errors.nfe ? `(${errors.nfe})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.nfe}
                onChange={() => {
                  handleChanges('nfe', entity.nfe === 0 ? 1 : 0);
                }}
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`Emite NFC-e
                ${errors.nfce ? `(${errors.nfce})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.nfce}
                onChange={() => {
                  handleChanges('nfce', entity.nfce === 0 ? 1 : 0);
                }}
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`Emite NFS-e
                ${errors.nfse ? `(${errors.nfse})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.nfse}
                onChange={() => {
                  handleChanges('nfse', entity.nfse === 0 ? 1 : 0);
                }}
              />
            </FormField>
            <FormField size="6%">
              <span>
                {`Emite Boleto
                ${errors.boleto ? `(${errors.boleto})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.boleto}
                onChange={() => {
                  handleChanges('boleto', entity.boleto === 0 ? 1 : 0);
                }}
              />
            </FormField>
            <FormField size="11%">
              <span>
                {`Consulta Notas Destinadas
                ${errors.consultaNotaDestinada ? `(${errors.consultaNotaDestinada})` : ''}
                `}
              </span>
              <Switch
                checked={!!entity.consultaNotaDestinada}
                onChange={() => {
                  handleChanges('consultaNotaDestinada', entity.consultaNotaDestinada === 0 ? 1 : 0);
                }}
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormButtons>
          <Button
            visual="secondary"
            type="button"
            onClick={() => navigate(-1)}
          >
            <FaReply size={20} color="#1362f5" />
            Voltar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default ManagementCompanyRegister;
