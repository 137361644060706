import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import { useAuth } from '../hooks/AuthContext';

interface DecodedParams {
  exp: number;
}

const AuthVerifyComponent = () => {
  const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('@3rsistemas-gerencial:token');
    if (token !== undefined && token !== null) {
      const { exp }: DecodedParams = jwt_decode(token || '');
      if (exp < (new Date().getTime() + 1) / 1000) {
        signOut();
      }
    }
  }, [location]);


  return <div />;
};

export default AuthVerifyComponent;
