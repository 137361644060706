import { ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function onlyNumbers(value: string): Promise<string> {
  return value.replace(/\D/g, '');
}

export interface ReactComponentProps {
  children?: ReactNode;
}
