import styled from 'styled-components';
import { shade } from 'polished';

export const Confirmation = styled.div`
  background: #262424;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${shade(0.2, '#262424')};
  border-radius: 10px;
  padding: 10px;
  z-index: 100;
`;

export const Message = styled.div`
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    margin-top: 24px;
    color: #fafafa;
    font-size: 20px;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  button {
    width: 140px;
    height: 40px;
    border: 0;
    border-radius: 5px;
    background: #1362f5;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
    & + button {
      margin-left: 10px;
    }
    &:hover {
      background: ${props => shade(0.2, '#1362f5')};
    }
  }
`;
