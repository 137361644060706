import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSave, FiXCircle } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';

import api from '../../../services/api';

import { getIndividualErrors, getErrors } from '../../../utils/validateForm';
import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';

import Confirmation from '../../../components/Confirmation';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';
import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormFieldInput,
  FormTitle,
  FormButtons,
  Button,
  FormFieldTextArea,
} from '../../../styles/registers';
import { changeNamesOfColumns } from '../../../utils/handleSelects';
import { useAuth } from '../../../hooks/AuthContext';

interface returnHandleValidation {
  b: boolean;
  fieldNameError: string;
}

interface EntityData {
  data: Date | null;
  cliente: string;
  colaborador: string;
  status: string;
  tipo: string;
  descricao: string;
}

interface ErrorsData {
  [key: string]: string;
}

const CustomerServiceRegister: React.FC = () => {

  const { user } = useAuth();

  const navigate = useNavigate();
  const { idCustomerService } = useParams<{ idCustomerService: string }>();
  const { addToast } = useToasts();

  const [entity, setEntity] = useState<EntityData>({
    data: new Date(),
    cliente: '',
    colaborador: '',
    descricao: '',
    status: 'ANDAMENTO',
    tipo: ''
  });

  const [entityValidation] = useState({
    data: Yup.date()
    .required('Preenchimento obrigatório').label('Data'),
    cliente: Yup.string()
    .required('Preenchimento obrigatório').label('Cliente'),
    colaborador: Yup.string()
    .required('Preenchimento obrigatório').label('Colaborador'),
    status: Yup.string()
    .required('Preenchimento obrigatório').label('Status'),
    tipo: Yup.string().required('Preenchimento obrigatório').label('Tipo'),
    descricao: Yup.string().nullable().test(
      'Verifica se a descrição está preenchida',
      (value: any, { createError, path }) => {
        let hasError = false;
        let message = '';
        if (value.trim().length == 0) {
          hasError = true;
          message = 'Informe a descrição';
        }
        if (hasError) {
          return createError({
            path,
            message,
          })
        }
        return true;
      }
    ).label('Descrição'),
  });
  const [errors, setErrors] = useState<ErrorsData>({});

  const [selectClients, setSelectClients] = useState([]);
  const [selectCollaborators, setSelectCollaborators] = useState([]);
  const [selectStatus] = useState([
    { label: 'ANDAMENTO', value: 'ANDAMENTO' },
    { label: 'FINALIZADO', value: 'FINALIZADO' },
  ]);
  const [selectTipo] = useState([
    { label: 'MANUTENÇÃO', value: 'MANUTENÇÃO' },
    { label: 'DÚVIDA', value: 'DÚVIDA' },
    { label: 'BUG', value: 'BUG' },
    { label: 'TREINAMENTO', value: 'TREINAMENTO' },
  ]);

  useEffect(() => {
    async function loadSelects() {
      try {
        const responseClient = await api.get('/clients', {
          params: {
            status: 'ativo',
          },
        });
        setSelectClients(
          changeNamesOfColumns(responseClient.data, 'fantasia', 'id'),
        );

        const response = await api.get('/collaborators', {
          params: {
            status: 'ativo'
          }
        });
        setSelectCollaborators(
          changeNamesOfColumns(response.data, 'nome', 'id'),
        );

      } catch (err) {
        //
      }
    }
    loadSelects();

    async function loadEntity() {
      if (idCustomerService !== undefined) {
        const response = await api.get(`/customerServices/${idCustomerService}`);
        const entityManipulated: any = loadForm(response.data, [], ['data'], []);

        delete entityManipulated.id;
        delete entityManipulated.created_at;
        delete entityManipulated.updated_at;

        console.log(entityManipulated)

        setEntity(entityManipulated);
      }
    }
    loadEntity();
  }, [idCustomerService]);

  useEffect(() => {
    async function loadCollaborator() {
      try {
        const response = await api.get('/collaborators', {
          params: {
            usuario: user.id,
          },
        });
        handleChanges('colaborador', response.data[0].id);
      } catch (err) {
        //
      }
    }
    if (idCustomerService === undefined) {
      setTimeout(() => {
        loadCollaborator();
      }, 1000);
    }
  }, [user.id]);

  async function handleValidation(
    field: string,
    general = false,
  ): Promise<returnHandleValidation> {
    let fieldNameError = '';
    let validate = true;
    const schema = Yup.object().shape(entityValidation);
    await schema
      .validate(entity, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
      })
      .catch(async function errs(err) {
        if (err.inner) fieldNameError = err.inner[0].params.label;
        const returnedErrors: ErrorsData = general
          ? getErrors(err)
          : getIndividualErrors(err, field, errors);
        setErrors(JSON.parse(JSON.stringify(returnedErrors)));
        validate = false;
      });
    return {
      b: validate,
      fieldNameError,
    };
  }

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  async function handleSubmit() {
    const validation = await handleValidation('', true);
    if (validation.b) {
      try {

        if (entity.descricao==='' && entity.status==='FINALIZADO') {
          addToast(
            'Para atendimentos com status finalizado, defina a descrição',
            {
              appearance: 'error',
              autoDismiss: true,
            },
          );
          return;
        }

        const entitySave = saveForm(entity, []);
        if (idCustomerService === undefined) {
          await api.post('/customerServices', entitySave);
        } else {
          await api.put(`/customerServices/${idCustomerService}`, entitySave);
        }
        addToast('Atendimento gravado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
        navigate(-1);
      } catch (err:any) {
        addToast(
          err.response
            ? err.response.data.message
            : 'Problemas ao gravar o atendimento, tente novamente',
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }
    } else {
      addToast(`Defina: ${validation.fieldNameError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Atendimentos</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS PRINCIPAIS</FormTitle>

          <FormLine>

            <FormField size="75%">
              <span>
                {`
                Cliente *
                ${errors.cliente ? `(${errors.cliente})` : ''}
                `}
              </span>
              <Select
                name="cliente"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o cliente"
                onBlur={(e: any) => handleValidation('cliente')}
                onChange={(e: any) => {
                  handleChanges('cliente', e.value);
                }}
                error={!!errors.cliente}
                defaultValue={entity.cliente}
                options={selectClients}
              />
            </FormField>

            <FormField size="25%">
              <span>
                {`
                Data *
                ${errors.data ? `(${errors.data})` : ''}
                `}
              </span>
              <DatePicker
                error={!!errors.data}
                onChange={(e: any) => {
                  return null;
                }}
                placeholderText={String(format(new Date(), 'dd/MM/yyyy'))}
                selected={entity.data}
              />
            </FormField>

          </FormLine>

          <FormLine>
            <FormField size="50%">
              <span>
                {`
                Colaborador *
                ${errors.colaborador ? `(${errors.colaborador})` : ''}
                `}
              </span>
              <Select
                name="colaborador"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o colaborador"
                onBlur={(e: any) => handleValidation('colaborador')}
                onChange={(e: any) => {
                  handleChanges('colaborador', e.value);
                }}
                error={!!errors.colaborador}
                defaultValue={entity.colaborador}
                options={selectCollaborators}
              />
            </FormField>

            <FormField size="25%">
              <span>Tipo *</span>
              <Select
                name="tipo"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o tipo"
                onBlur={(e: any) => handleValidation('tipo')}
                onChange={(e: any) => {
                  handleChanges('tipo', e.value);
                }}
                error={!!errors.tipo}
                defaultValue={entity.tipo}
                options={selectTipo}
              />
            </FormField>

            <FormField size="25%">
              <span>
                {`
                Status *
                ${errors.status ? `(${errors.status})` : ''}
                `}
              </span>
              <Select
                name="status"
                height={40}
                maxMenuHeight={300}
                placeholder="Escolha o status"
                onBlur={(e: any) => handleValidation('status')}
                onChange={(e: any) => {
                  handleChanges('status', e.value);
                }}
                error={!!errors.status}
                defaultValue={entity.status}
                options={selectStatus}
              />
            </FormField>

          </FormLine>

          <FormLine height="250px">
            <FormField size="100%">
              <span>Descrição *</span>
              <FormFieldTextArea
                height="210px"
                onBlur={e => handleValidation(e.target.id)}
                onChange={e => {
                  handleChanges(e.target.id, e.target.value);
                }}
                value={entity.descricao}
                autoComplete="off"
                id="descricao"
                error={!!errors.descricao}
                placeholder="Digite a descrição"
              />
            </FormField>
          </FormLine>

        </FormBlock>

        <FormButtons>
          <Button onClick={handleSubmit} type="button">
            <FiSave size={20} color="#ffffff" />
            Salvar
          </Button>
          <Button
            visual="secondary"
            type="button"
            onClick={() => {
              Confirmation(
                () => navigate(-1),
                'Tem certeza que deseja cancelar este cadastro ?',
                'abort',
              );
            }}
          >
            <FiXCircle size={20} color="#1362f5" />
            Cancelar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default CustomerServiceRegister;
